

import React from 'react';


interface FeatureCardProps {
  icon: React.ReactElement; // Stricter typing for React elements
  title: string;
  description: string;
}

export function FeatureCard({ icon, title, description }: FeatureCardProps) {
  return (
    <div
      className="bg-white rounded-lg p-8 shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
      role="region"
      aria-labelledby={`${title}-id`}
    >
      <div className="text-[#FF4747] mb-6">
        {React.cloneElement(icon, { fontSize: 'large' })}
      </div>
      <h3 className="text-[#FF4747] font-semibold text-xl mb-3" id={`${title}-id`}>
        {title}
      </h3>
      <p className="text-gray-600 leading-relaxed , max-w-[150px]">{description}</p>
    </div>
  );
}

export function Optimize() {
  const features = [
    {
      icon:  <img src="/assets/decrease .png" alt='' />,
      title: 'OPTIMISES',
      description: 'spending with smarter cost management',
    },
    {
      icon: <img src="/assets/setting.png" alt='' />,
      title: 'STREAMLINES',
      description: 'operations with comprehensive observability',
    },
    {
      icon: <img src="/assets/prevent.png" alt='' />,
      title: 'PREVENTS',
      description: 'disruptions with proactive anomaly detection',
    },
    {
      icon: <img src="/assets/eye.png"alt='' />,
      title: 'MONITORS',
      description: 'cloud seamlessly with real-time insights.',
    },
  ];

  return (
<section className="min-h-[632px] bg-gray-100 py-16">

      <div className="container mx-auto px-4 max-w-7xl">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-center mb-16">
          <span className="text-[#FF4747]">CB Vista</span> Gives You Total Clarity
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Optimize;
import React from "react";
import { Routes, Route } from "react-router-dom";
import Active from "./pages/Resources/active/page";
import CostReports from "./pages/Resources/costreport/page";
import Costlist from "./pages/Resources/costdata/page";
import List from "./pages/monitorminds/monitor/page";
import Create from "./pages/monitorminds/create/page";
import Status from "./pages/monitorminds/status/page";
import CreateStatusPage from "./pages/monitorminds/statuspage/page";
import CostRecommendations from "./pages/Recommendation/costsaving/page";
import Resources from "./pages/Recommendation/resource/page";
import PrivateRoute from "./components/PrivateRoute";
import Dashboard from "./pages/dashboard/page";
import Profile from "./pages/Profile/page";
import AnomalyDetails from "./pages/Anomaly/Anomalydetails/page";
import SignupPage from "./pages/Signup/page";
import PublicHeaderPage from "./pages/PublicProfile/page";

import Finops from "./pages/Finops/FinopsCost/page";

import QuotasAndBudgets from "./pages/setting/budget/page";
import CreateQuota from "./pages/setting/CreateQuota/page";
import DataSource from "./pages/setting/DataSource/page";
import Platform from "./PublicPages/Platform.tsx/page";
import Allocation from "./pages/setting/page";
import CostAllocation from "./pages/Finops/CostAllocation/page";
import CashFlow from "./PublicPages/cashflow/page";









interface RouteConfig {
  path: string;
  component: React.ReactNode;
  private: boolean;
}
  
const routes: RouteConfig[] = [
  
  { path: "/resources/active", component: <Active />, private: true },
  { path: "/", component: <Dashboard />, private: true },
  { path: "/profile", component: <Profile />, private: true },

  { path: "/monitorminds/list", component: <List />, private: true },
  { path: "/monitorminds/create", component: <Create />, private: true },
  { path: "/monitorminds/status", component: <Status />, private: true },
  { path: "/monitorminds/statuspage", component: < CreateStatusPage />, private: true },
  { path: "/Recommendation/cost", component: < CostRecommendations />, private: true },
  { path: "/Recommendation/resource", component: < Resources />, private: true },
  { path: "/dashboard", component: < Dashboard />, private: true },
  { path: "/costlist", component: <CostRecommendations />, private: true },
  // { path: "/resources/costreport", component: <CostReports />, private: true },
  { path: "/resources/costdata", component: <Costlist />, private: true },
  { path: "/Anomaly/Anomalydetails", component: <AnomalyDetails />, private: true },
  { path: "/Finop/FinopCost", component: < Finops />, private: true },
  { path: "/Finop/CostAllocation", component: < CostAllocation />, private: true },
  { path: "/setting/organization", component: < Allocation />, private: false },
  { path: "/setting/budget", component: < QuotasAndBudgets />, private: false },
  { path: "/setting/createQuota", component: < CreateQuota/>, private: false },
  { path: "/setting/DataSource", component: < DataSource/>, private: false },
{ path: "/signup", component: <SignupPage />, private: false },
{ path: "/Platform", component: <Platform />, private: false },
{ path: "/cashflow", component: <CashFlow />, private: false },






// { path: "/", component: <PublicHeaderPage />, private: false },
];

const AppRoutes: React.FC = () => {
  return (
    <Routes>
    
      {routes.map(({ path, component, private: isPrivate }) => (
        
        <Route
          key={path}
          path={path}
          element={
            isPrivate ? <PrivateRoute>{component}</PrivateRoute> : component
          }
        />
      ))}
      
    </Routes>
    
    
  );
};

export default AppRoutes;




import React, { useState, useEffect } from 'react';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import PageLayout from '../../../components/PageLayout';
import { createApiClient } from "../../../modules/services/axios";
import ReactECharts from 'echarts-for-react';
import { useTokens } from "../../../modules/services/token";



interface WidgetData {
  tag_names: string[];
  service_codes: string[];
  service_categories: string[];
}

interface GroupedCostData {
  ServiceName: string;
  total_cost: number;
}

const Finops: React.FC = () => {
    const { accessToken,idToken } = useTokens(); 
  const [, setWidgetData] = useState<WidgetData | null>(null);
  const [groupedCostData, setGroupedCostData] = useState<GroupedCostData[]>([]);
  const [, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [groupBy, setGroupBy] = useState<string>('ServiceName');
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);

  const groupByOptions = ['ServiceName', 'Region', 'UsageType']; // Example group_by options


  const apiClient = createApiClient();

  useEffect(() => {
  
    const fetchWidgetData = async () => {
      if (!idToken || !accessToken) 

        return;
  
      try {
        const response = await apiClient.get('/api/finops/dashboard/widgets', {
        
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use the idToken here
            id_token: idToken, // Change the header key to `id_token`
          },
        });
        console.log(response.data.widgets);
        setWidgetData(response.data.widgets);
      } catch (err) {
        setError('Error fetching widget data. Please try again later.');
      }
    };

    fetchWidgetData();
  }, [idToken,accessToken]);

  useEffect(() => {
    const fetchGroupedCostData = async () => {
      if (!idToken || !accessToken) return;
      try {
        setLoading(true);
        const response = await apiClient.get('/api/finops/grouping_with_total_cost',{
          params: { group_by: groupBy, limit: 100 },
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use the idToken here
            id_token: idToken, // Change the header key to `id_token`
            
          },
        });

        setGroupedCostData(response.data.results.map((item: any) => ({
          ServiceName: item.ServiceName,
          total_cost: parseFloat(item.total_cost),
        })));
        setLoading(false);
      } catch (err) {
        setError('Error fetching grouped cost data. Please try again later.');
        setLoading(false);
      }
    };

    fetchGroupedCostData();
  },  [idToken, accessToken, groupBy]);

  const toggleDropdown = (dropdown: string) => {
    setOpenDropdown(openDropdown === dropdown ? null : dropdown);
  };

  const Dropdown = ({
    items,
    selectedItem,
    setSelectedItem,
    label,
    dropdownKey,
  }: {
    items: string[];
    selectedItem: string;
    setSelectedItem: React.Dispatch<React.SetStateAction<string>>;
    label: string;
    dropdownKey: string;
  }) => (
    <div className="relative inline-block text-sm">
      <button
        onClick={() => toggleDropdown(dropdownKey)}
        className="border border-gray-300 rounded-md py-1 px-2 bg-white text-left shadow-sm focus:outline-none focus:ring-1 focus:ring-indigo-500"
      >
        {label}: {selectedItem}
      </button>
      {openDropdown === dropdownKey && (
        <div className="absolute mt-1 w-full bg-white shadow-lg max-h-40 overflow-auto border border-gray-300 rounded-md z-10 text-xs">
          {items.map(item => (
            <div
              key={item}
              className="flex items-center px-2 py-1 hover:bg-gray-100 cursor-pointer"
              onClick={() => {
                setSelectedItem(item);
                toggleDropdown(dropdownKey);
              }}
            >
              <span>{item}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const generateChartOptions = () => {
    const colorMap: Record<string, string> = {};
    const colors = [
      '#5470C6', '#91CC75', '#FAC858', '#EE6666', '#73C0DE',
      '#3BA272', '#FC8452', '#9A60B4', '#EA7CCC',
    ];

    // Assign colors to each service
    groupedCostData.forEach((item, index) => {
      colorMap[item.ServiceName] = colors[index % colors.length];
    });

    return {
      title: {
        text: `Daily Costs Grouped by ${groupBy}`,
        left: 'center',
      },
      tooltip: {
        trigger: 'axis',
      },
      xAxis: {
        type: 'category',
        data: groupedCostData.map(item => item.ServiceName),
        name: groupBy,
      },
      yAxis: {
        type: 'value',
        name: 'Cost',
      },
      series: [
        {
          data: groupedCostData.map(item => ({
            value: item.total_cost,
            itemStyle: { color: colorMap[item.ServiceName] },
          })),
          type: 'bar',
        },
      ],
    };
  };

 

  if (error) {
    return (
      <Container>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-100 p-4">
        <Container maxWidth="lg" className="mt-4 mb-4">
          <Typography variant="subtitle1" component="h1" gutterBottom>
            FinOps Dashboard
          </Typography>

          {/* Group By Dropdown */}
          <div className="flex space-x-2 mb-6">
            <Dropdown
              items={groupByOptions}
              selectedItem={groupBy}
              setSelectedItem={setGroupBy}
              label="Group By"
              dropdownKey="groupBy"
            />
          </div>

          {/* Bar Chart */}
          <div className="mt-6">
            <ReactECharts option={generateChartOptions()} />
          </div>

          {/* Table */}
          <div className="mt-6">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>{groupBy}</strong></TableCell>
                    <TableCell><strong>Total Cost</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedCostData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell>{item.ServiceName}</TableCell>
                      <TableCell>{parseFloat(item.total_cost.toFixed(6))}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Container>
      </div>
    </PageLayout>
  );
};

export default Finops;



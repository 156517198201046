

import { Grid } from '@mui/material'


 function View() {
  return (
    <div style={{minHeight:"632px"}}>
      <Grid container spacing={2}   sx={{
          alignItems: 'center',
          padding: { xs: '16px', sm: '24px', md: '64px' }, // Responsive padding for different breakpoints
        }}
      >
        <Grid item  xs={12} sm={12} md={6}>
       <div style={{marginLeft:"64px"}}>
       <span style={{ color: "#E53E3E",  fontWeight: "bold", }}>COMPLETE VIEW</span>
        <h1 style={{
                color: "black",
                fontSize: "48px",
                fontWeight: "bold",
                lineHeight: "1.2",
                margin: "20px 0",
              }}> Stop Hunting, Start <br /> Solving</h1>
    <p
              style={{
                color: "#4A5568", // Slightly grayish text color
                fontSize: "18px",
                lineHeight: "1.6",
                maxWidth: "500px",
              }}
            >
              Eliminate the guesswork and identify what’s <span style={{ color: "#E53E3E", fontWeight: "bold" }}>really</span> happening in your system. Correlate data to understand why issues occur and address them with confidence.
            </p>
       </div>
        </Grid>
        <Grid item  md={6}>
            
    <img src="/assets/view.png" alt='' />
        </Grid> 
        
     
       
      </Grid>
    </div>
  )
}
export default View;
import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link } from "react-router-dom";
import PageLayout from "../../../components/PageLayout";

interface Quota {
  name: string;
  lastCheck: string;
  status: string | number;
  description: string;
  filters: string;
  exceeded: boolean;
}

const QuotasAndBudgets: React.FC = () => {
  const quotas: Quota[] = [
    {
      name: "Buckets count in eu-west-2",
      lastCheck: "1 day ago",
      status: 0,
      description: "Resource count must not exceed 10.",
      filters: "Region: eu-west-2, Resource type: Bucket",
      exceeded: false,
    },
    {
      name: "Buckets count in us-east-1",
      lastCheck: "1 day ago",
      status: 12,
      description: "Resource count must not exceed 3.",
      filters: "Region: us-east-1, Resource type: Bucket",
      exceeded: true,
    },
    {
      name: "Environments total budget",
      lastCheck: "1 day ago",
      status: "$554.6",
      description: "Total expenses must not exceed $2,650.",
      filters: "Pool: Environment",
      exceeded: true,
    },
    {
      name: "Monthly S3 expenses quota",
      lastCheck: "1 day ago",
      status: "$193.41",
      description: "Current month expenses must not exceed $5,300.",
      filters: "Service: AmazonS3",
      exceeded: true,
    },
  ];

  return (
    <PageLayout>
      <div className="p-6 bg-gray-100 h-screen">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-2xl font-semibold">Quotas and Budgets</h1>
          {/* <Link to="/setting/createQuota">
            <Button
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              className="capitalize"
            >
              Add
            </Button>
          </Link> */}
        </div>

        {/* Table */}
        <div className="mt-6">
          <Table className="shadow bg-white rounded">
            <TableHead>
              <TableRow className="bg-gray-100 text-left">
                <TableCell>Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Filters</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quotas.map((quota, index) => (
                <TableRow
                  key={index}
                  className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                >
                  <TableCell>
                    <div>
                      <div className="font-medium text-blue-600">
                        {quota.name}
                      </div>
                      <div className="text-gray-500 text-xs">
                        Last check: {quota.lastCheck}
                      </div>
                    </div>
                  </TableCell>
                  <TableCell>
                    <span
                      className={`px-2 py-1 rounded text-xs ${
                        quota.exceeded
                          ? "bg-red-100 text-red-600"
                          : "bg-green-100 text-green-600"
                      }`}
                    >
                      {quota.status}
                    </span>
                  </TableCell>
                  <TableCell>{quota.description}</TableCell>
                  <TableCell>{quota.filters}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </PageLayout>
  );
};

export default QuotasAndBudgets;



import React, { useEffect, useState } from "react";
import axios from "axios";
import PageLayout from "../../components/PageLayout";
import { FaTrash } from "react-icons/fa";

type Entry = {
  name: string;
  limit: number | null;
  expenses_this_month: number;
  forecast_this_month: number;
  owner: string;
  type: "organization" | "project" | "environment" | "budget" | "team"; 
  children?: Entry[];
};

type Overview = {
  spent_over_limit: number;
  exceeded_limit: number;
  organization_limit: number;
  expenses_this_month: number;
  forecast_this_month: number;
  possible_monthly_savings: number;
};

type Data = {
  overview: Overview;
  entries: Entry[];
};

const Allocation: React.FC = () => {
  const [data, setData] = useState<Data | null>(null);
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [showPopup, setShowPopup] = useState(false);
   const [loading, setLoading] = useState<boolean>(true);
  const [newEntry, setNewEntry] = useState({
    name: "",
    limit: "",
    type: "organization", 
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/data.json");
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const toggleExpand = (name: string) => {
    setExpanded((prev) => ({ ...prev, [name]: !prev[name] }));
  };

  const handlePopupToggle = () => {
    setShowPopup((prev) => !prev);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setNewEntry((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddEntry = () => {
    const newEntryData: Entry = {
      name: newEntry.name,
      limit: newEntry.limit ? parseFloat(newEntry.limit) : null,
      expenses_this_month: 0, 
      forecast_this_month: 0,
      owner: "Unknown", 
      type: newEntry.type as "organization" | "project" | "environment" | "budget" | "team",
    };

    if (data) {
      if (newEntry.type === "organization") {
        setData((prevData) => ({
          ...prevData!,
          entries: [...prevData!.entries, newEntryData],
        }));
      } else if (newEntry.type === "project") {
        const updatedEntries = [...data.entries];
        updatedEntries[0].children = [...(updatedEntries[0].children || []), newEntryData];
        setData({ ...data, entries: updatedEntries });
      } else if (newEntry.type === "environment") {
        const updatedEntries = [...data.entries];
        const projectEntry = updatedEntries[0].children?.[0]; 
        if (projectEntry) {
          projectEntry.children = [...(projectEntry.children || []), newEntryData];
        }
        setData({ ...data, entries: updatedEntries });
      }
    }

    setShowPopup(false); 
  };

  const handleDeleteEntry = (name: string) => {
    if (data) {
      const deleteRecursively = (entries: Entry[], name: string): Entry[] => {
        return entries
          .filter(entry => entry.name !== name)
          .map(entry => ({
            ...entry,
            children: entry.children ? deleteRecursively(entry.children, name) : [],
          }));
      };

      const updatedEntries = deleteRecursively(data.entries, name);
      setData({ ...data, entries: updatedEntries });
    }
  };

  const renderTable = (entries: Entry[], depth = 0) =>
    entries.map((entry, index) => (
      <React.Fragment key={index}>
        <tr className={`hover:bg-gray-50 border-t border-gray-200`}>
          <td className="px-4 py-3">
            <div className="flex items-center" style={{ paddingLeft: `${depth * 24}px` }}>
              {entry.children && entry.children.length > 0 && (
                <button
                  onClick={() => toggleExpand(entry.name)}
                  className="mr-2 w-4 h-4 flex items-center justify-center text-gray-500"
                >
                  <svg className="w-3 h-3" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3">
                    <path d={expanded[entry.name] ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"} />
                  </svg>
                </button>
              )}
              <span className="flex items-center">
                {entry.type === "organization" && (
                  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M4 4h16v16H4z M4 8h16 M8 4v4 M16 4v4 M8 12h8" strokeWidth="2"/>
                  </svg>
                )}
                {entry.type === "project" && (
                  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z" strokeWidth="2"/>
                  </svg>
                )}
                {entry.type === "environment" && (
                  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M13 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V9z M13 2v7h7" strokeWidth="2"/>
                  </svg>
                )}
                {entry.type === "budget" && (
                  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M19 12H5" strokeWidth="2"/>
                  </svg>
                )}
                {entry.type === "team" && (
                  <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                    <path d="M12 12h8m0 0v8m0-8l-8-8-8 8" strokeWidth="2"/>
                  </svg>
                )}
                {entry.name}
              </span>
            </div>
          </td>
          <td className="px-4 py-3 text-right">
            {entry.limit ? `$${entry.limit.toLocaleString()}` : "-"}
          </td>
          <td className="px-4 py-3 text-right">
            <div className={`${entry.expenses_this_month > (entry.limit || 0) ? 'text-red-600' : 'text-green-600'}`}>
              ${entry.expenses_this_month.toLocaleString()}
              {entry.limit && entry.expenses_this_month > entry.limit && (
                <span className="text-red-600 ml-1">{`(x${(entry.expenses_this_month / entry.limit).toFixed(1)})`}</span>
              )}
            </div>
          </td>
          <td className="px-4 py-3 text-right">
            <div className="text-blue-600">
              ${entry.forecast_this_month.toLocaleString()}
              {entry.limit && entry.forecast_this_month > entry.limit && (
                <span className="text-red-600 ml-1">{`(x${(entry.forecast_this_month / entry.limit).toFixed(1)})`}</span>
              )}
            </div>
          </td>
          <td className="px-4 py-3">{entry.owner}</td>
          <td className="px-4 py-3">
            <div className="flex items-center justify-end space-x-2">
              <button
                onClick={handlePopupToggle}
                className="p-1 hover:bg-gray-100 rounded"
              >
                <svg className="w-4 h-4" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                  <path d="M12 4v16m8-8H4" />
                </svg>
              </button>
              <button
                onClick={() => handleDeleteEntry(entry.name)}
                className="p-1 hover:bg-gray-100 rounded text-gray-500 hover:text-gray-700"
              >
                <FaTrash className="w-4 h-4" />
              </button>
            </div>
          </td>
        </tr>
        {entry.children && expanded[entry.name] && renderTable(entry.children, depth + 1)}
      </React.Fragment>
    ));


  return (
    <PageLayout>
   
      <div className="h-screen bg-gray-100 p-4 flex flex-col">
      {data ? (
      <>
        {/* Overview Section */}
        <div className="flex flex-wrap gap-4 mb-4 justify-between">
          <div className="bg-blue-100 text-blue-800 p-4 rounded-lg shadow-md w-1/6">
            <div className="text-sm font-medium">Spent Over Limit</div>
            <div className="text-2xl font-semibold">
              ${data.overview.spent_over_limit.toLocaleString()}
            </div>
          </div>
          <div className="bg-yellow-100 text-yellow-800 p-4 rounded-lg shadow-md w-1/6">
            <div className="text-sm font-medium">Exceeded Limit</div>
            <div className="text-2xl font-semibold">
              {data.overview.exceeded_limit}
            </div>
          </div>
          <div className="bg-green-100 text-green-800 p-4 rounded-lg shadow-md w-1/6">
            <div className="text-sm font-medium">Organization Limit</div>
            <div className="text-2xl font-semibold">
              ${data.overview.organization_limit.toLocaleString()}
            </div>
          </div>
          <div className="bg-orange-100 text-orange-800 p-4 rounded-lg shadow-md w-1/6">
            <div className="text-sm font-medium">Expenses This Month</div>
            <div className="text-2xl font-semibold">
              ${data.overview.expenses_this_month.toLocaleString()}
            </div>
          </div>
          <div className="bg-purple-100 text-purple-800 p-4 rounded-lg shadow-md w-1/6">
            <div className="text-sm font-medium">Possible Monthly Savings</div>
            <div className="text-2xl font-semibold">
              ${data.overview.possible_monthly_savings.toLocaleString()}
            </div>
          </div>
        </div>

        {/* Table Section */}
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="w-full ">
            <thead>
              <tr className="border-y bg-gray-300 border-gray-100">
                <th className="px-4 py-3 text-left font-bold text-black-600">Name</th>
                <th className="px-4 py-3 text-right font-bold text-black-600">Limit</th>
                <th className="px-4 py-3 text-right font-bold text-black-600">This Month's Expenses</th>
                <th className="px-4 py-3 text-right font-bold text-black-600">Forecast This Month</th>
                <th className="px-4 py-3 text-left font-bold text-black-600">Owner</th>
                <th className="px-4 py-3 text-center font-bold text-black-600">Actions</th>
              </tr>
            </thead>
            <tbody>{renderTable(data.entries)}</tbody>
          </table>
        </div>
        </>
    ) : (
      <p className="text-center">Loading...</p>
    )}

        {/* Popup for Adding New Entry */}
        {showPopup && (
          <div className="fixed inset-0 bg-gray-700 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-2xl font-bold mb-4">Add New Entry</h2>
              <label className="block text-gray-700 mb-2">
                Name:
                <input
                  type="text"
                  name="name"
                  value={newEntry.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </label>
              <label className="block text-gray-700 mb-2">
                Limit:
                <input
                  type="number"
                  name="limit"
                  value={newEntry.limit}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                />
              </label>
              <label className="block text-gray-700 mb-2">
                Type:
                <select
                  name="type"
                  value={newEntry.type}
                  onChange={handleInputChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
                >
                  <option value="organization">Organization</option>
                  <option value="project">Project</option>
                  <option value="environment">Environment</option>
                  <option value="budget">Budget</option>
                  <option value="team">Team</option>
                </select>
              </label>
              <div className="flex justify-between items-center mt-4">
                <button
                  onClick={handleAddEntry}
                  className="bg-blue-600 text-white py-2 px-4 rounded-lg"
                >
                 Create
                </button>
                <button
                  onClick={handlePopupToggle}
                  className="bg-gray-600 text-white py-2 px-4 rounded-lg"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Allocation;



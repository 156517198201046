


import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import PageLayout from '../../../components/PageLayout';
import { createApiClient } from "../../../modules/services/axios";
import { useTokens } from "../../../modules/services/token";

interface Recommendation {
  potential_savings: string;
  service: string;
  monthlySavings: number | null;
  description: string;
  token: string;
  resources_affected_count: number;
}



const CostRecommendations: React.FC = () => {
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { accessToken, idToken } = useTokens(); // Fetch tokens
  const hasFetchedData = useRef(false); // Prevent multiple API calls
 
  useEffect(() => {
      const apiClient = createApiClient();
    const fetchRecommendations = async () => {
      if (hasFetchedData.current || !accessToken || !idToken) return; // Prevent duplicate calls or missing tokens
      hasFetchedData.current = true;
      try {
        const response = await apiClient.get(`/api/vantage/recommendations`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setRecommendations(response.data.recommendations || []);
      } catch (error) {
        console.error('Error fetching recommendations:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  },  [accessToken, idToken]);
  

  return (
    <PageLayout>
      <div className="flex flex-col min-h-screen bg-gray-100">
        {/* Content Area */}
        <div className="flex-1 p-3 md:p-6 bg-gray rounded-lg shadow-lg">
          <h2 className="text-3xl font-semibold mb-4">Cost Savings</h2>

          {loading ? (
            <p>Loading...</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray border border-gray-200 rounded-lg">
                <thead className="bg-gray-400">
                  <tr>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Service</th>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Monthly Savings</th>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {recommendations.map((rec, index) => (
                    <tr key={index} className="border-t hover:bg-gray-100">
                      <td
                        className={`px-4 py-2 ${
                          rec.resources_affected_count > 0 ? 'text-blue-500 cursor-pointer' : ''
                        }`}
                      >
                        {rec.resources_affected_count > 0 ? (
                          <Link
                            to={`/Recommendation/resource?token=${rec.token}`} // Using Link component for navigation
                            className="hover:underline"
                          >
                            {rec.service}
                          </Link>
                        ) : (
                          rec.service
                        )}
                      </td>
                      <td className="px-4 py-2 text-green-600">
                        ${rec.potential_savings ? parseFloat(rec.potential_savings).toFixed(2) : '0.00'}
                      </td>
                      <td className="px-4 py-2">{rec.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default CostRecommendations;



import { Grid } from '@mui/material'


 function Latency() {
  return (
    <div>
    
      <Grid container spacing={2} style={{alignItems:"center"}}>
        <Grid item  md={6}>
       
    <img style={{marginLeft:"64px"}} src="/assets/anomaly.png" alt='' />
        </Grid> 
        <Grid item  md={6}>
       <div>
       <span style={{ color: "#E53E3E",  fontWeight: "bold", }}>PROACTIVE APPROACH</span>
        <h1 style={{
                color: "black",
                fontSize: "48px",
                fontWeight: "bold",
                lineHeight: "1.2",
                margin: "20px 0",
              }}> AI-Driven ,Anomaly  <br /> Detection</h1>
    <p
              style={{
                color: "#4A5568", // Slightly grayish text color
                fontSize: "18px",
                lineHeight: "1.6",
                maxWidth: "500px",
              }}
            >
Harness the power of AI to detect abnormal behavior, from cost spikes to performance issues, and get proactive recommendations to resolve problems before they impact your users.
            </p>
       </div>
        </Grid>
       
      </Grid>
    </div>
   
  )
}

export default Latency



import React, { useState, useEffect } from "react";
import PageLayout from "../../../components/PageLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { createApiClient } from "../../../modules/services/axios";
import Graph from "../../../components/graph/linegraph";
import { useTokens } from "../../../modules/services/token";
import Pagination from "../../../ui/Pagination";

interface ApiResponse {
  time: string;
  rolling_mean: string;
  rolling_std: string;
  upper_bound: string;
  lower_bound: string;
  residual: string;
  anomaly: string;
  value: string;
  service: string; // Add service property
  route: string;
}

interface FormData {
  anomalyType: string;
  service: string;
  route: string;
  method: string;
  From: Date;
  To: Date;
}

const AnomalyDetails: React.FC = () => {
  const [formData, setFormData] = useState<FormData>(() => {
    const currentDate = new Date();
    const oneDayAgo = new Date(currentDate);
    oneDayAgo.setDate(currentDate.getDate() - 1); // Set `From` to 24 hours ago

    return {
      anomalyType: "Latency",
      service: "",
      route: "",
      method: "",
      From: oneDayAgo,
      To: currentDate,
    };
  });

  const dropdownOptions = {
    anomalyType: ["Latency"],
  };

  const [services, setServices] = useState<string[]>([]);
  const [routes, setRoutes] = useState<string[]>([]);
  const [methods, setMethods] = useState<string[]>([]);
  const [graphData, setGraphData] = useState<ApiResponse[]>([]);
  const [showGraph, setShowGraph] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [validationError, setValidationError] = useState<string | null>(null);
  

    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [currentModalPage, setCurrentModalPage] = useState(1);
    const [rowsPerPageForModal, setRowsPerPageForModal] = useState(10); // Default 5 rows per page

    const rowsPerPage = 10;
  
    // Modal State
    const [modalOpen, setModalOpen] = useState(false);
    const [modalData, setModalData] = useState<any>(null); // To hold logs in pretty JSON format
    const [expandedLogs, setExpandedLogs] = useState<{[key: number]: boolean}>({});
    const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
   
    const [loadingLogs, setLoadingLogs] = useState(false);
const [, setLoadingTraces] = useState(false);

    const [severity, setSeverity] = useState<string>("ALL"); 

const { accessToken,idToken } = useTokens(); 
  

  useEffect(() => {
    const fetchDropdownData = async () => {
      if (!idToken || !accessToken) 

        return;
      try {
        const apiClient = createApiClient();
        const response = await apiClient.get(`/api/anomaly/dashboard_details`, {
        
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use the idToken here
            id_token: idToken, // Change the header key to `id_token`
          },
        });
       
        setServices(response.data.services || []);
        setRoutes(response.data.routes || []);
        setMethods(response.data.methods || []);
      } catch (err) {
        console.error("Error fetching dropdown data:", err);
        setError("Error fetching dropdown data.");
      }
    };

    fetchDropdownData();
  }, [accessToken, idToken]);

  const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleDateChange = (date: Date | null, field: "From" | "To") => {
    if (date) {
      setFormData((prev) => ({
        ...prev,
        [field]: date,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const { anomalyType, service, route, method, From, To } = formData;

    if (!service || !route || !method || !From || !To) {
      setValidationError("Please fill in all fields before submitting.");
      return;
    }

    const FromTimestamp = From.getTime();
    const ToTimestamp = To.getTime();

    

    if (FromTimestamp >= ToTimestamp) {
      setValidationError("From date must be earlier than To date.");
      return;
    }

    setValidationError(null);
    setLoading(true);
    setError(null);

    try {
      const apiClient = createApiClient();
      const queryParams = new URLSearchParams({
        services: service,
        methods: method,
        routes: route,
        anomalyType,
        From: FromTimestamp.toString(),
        To: ToTimestamp.toString(),
      });

      const response = await apiClient.get(`/api/anomaly/hit_query?${queryParams.toString()}`,{
        
        headers: {
          Authorization: `Bearer ${accessToken}`, // Use the idToken here
          id_token: idToken, // Change the header key to `id_token`
        },
      });
      if (Array.isArray(response.data.result)) {
        const updatedGraphData = response.data.result.map((item: any) => ({
          ...item,
          service: formData.service, // Add selected service to each row
          route: formData.route,     // Add selected route to each row
        }));
        setGraphData(updatedGraphData);
        setShowGraph(true);
      } else {
        setError("Anomaly data could not be loaded properly.");
      }
    } catch (err) {
      setError("Table not found.");
      setShowGraph(false); // Hide the graph on error
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const fetchLogsOrTraces = async (timestamp: string, type: "logs" | "traces", service: string,
    route: string) => {
    try {
      const timestampUnix = Math.floor(new Date(timestamp).getTime() / 1000);
       // Determine the severity level to send in the payload
    const severityLevel = severity === "ALL" ? undefined : severity;
      const apiClient = createApiClient();
        // Set loading state
        if (type === "logs") {
          setLoadingLogs(true);
        } else {
          setLoadingTraces(true);
        }
      const response = await apiClient.get(`/api/anomaly/query_logs`, {
        params: {timestamp: timestampUnix,service, severity: severityLevel, },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
   
      });

      console.log('API Response:', response.data);
     
   
      const logs = response.data.map((item: any) => ({
        timestamp: item.timestamp || "N/A",
        body: item.log?.body || "N/A",
        traceid: item.log?.traceid || "N/A",
      }));

      setModalData({ logs });
      setModalOpen(true);
    } catch (err) {
      console.error("Error fetching logs:", err);
      setModalData({
        logs: [],
        error: "No logs found for this time range.",
      });
      setModalOpen(true);
    } finally {
      setLoadingLogs(false);
    }
  };

  const handleViewLogs = (time: string, index: number) => {
    const selectedService = graphData[index]?.service
    const selectedRoute = graphData[index]?.route

    if (!selectedService || !selectedRoute) {
      console.warn("Service or Route not available for the selected row.")
      return
    }

    setSelectedRowIndex(index)
    fetchLogsOrTraces(time, "logs", selectedService, selectedRoute)
  }

  const handleViewTraces = (time: string, index: number) => {
    const selectedService = graphData[index]?.service
    const selectedRoute = graphData[index]?.route

    if (!selectedService || !selectedRoute) {
      console.warn("Service or Route not available for the selected row.")
      return
    }

    setSelectedRowIndex(index)
    fetchLogsOrTraces(time, "traces", selectedService, selectedRoute)
  }

  const handleViewTraceDetails = async (traceid: string) => {
    try {
      const apiClient = createApiClient();
      const response = await apiClient.get(`/api/anomaly/query_traces_v2`, {
        params: { traceid },
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_token: idToken,
        },
      });

      // Open a new tab and write the fetched trace data
      const newTab = window.open();
      if (newTab) {
        newTab.document.write(
          `<html>
            <head>
              <title>Trace Details</title>
              <style>
                body {
                  font-family: Arial, sans-serif;
                  margin: 20px;
                  line-height: 1.6;
                }
                pre {
                  background: #f4f4f4;
                  padding: 10px;
                  border-radius: 5px;
                  overflow: auto;
                }
              </style>
            </head>
            <body>
              <h1>Trace Details</h1>
              <pre>${JSON.stringify(response.data, null, 2)}</pre>
            </body>
          </html>`
        );
        newTab.document.close();
      }
    } catch (err) {
      console.error("Error fetching trace details:", err);
      alert("Failed to fetch trace details. Please try again.");
    }
  };


  const toggleLogExpansion = (index: number) => {
    setExpandedLogs((prev) => ({ ...prev, [index]: !prev[index] }))
  }

  const calculateTotalPages = (dataLength: number, itemsPerPage: number) => Math.ceil(dataLength / itemsPerPage)

  
  return (
    <PageLayout>
      <div className="p-4 bg-gray-100 min-h-screen">
        <form onSubmit={handleSubmit} className="w-full max-w-6xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-800 mb-6">Anomaly</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-6">
            {/* Anomaly Type */}
            <div>
              <label htmlFor="anomalyType" className="block text-sm font-bold text-gray-700 mb-1">
                Anomaly Type
              </label>
              <select
                id="anomalyType"
                name="anomalyType"
                value={formData.anomalyType}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Anomaly Type</option>
                {dropdownOptions.anomalyType.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Service */}
            <div>
              <label htmlFor="service" className="block text-sm font-bold text-gray-700 mb-1">
                Service
              </label>
              <select
                id="service"
                name="service"
                value={formData.service}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Service</option>
                {services.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Method */}
            <div>
              <label htmlFor="method" className="block text-sm font-bold text-gray-700 mb-1">
                Method
              </label>
              <select
                id="method"
                name="method"
                value={formData.method}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Method</option>
                {methods.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Route */}
            <div>
              <label htmlFor="route" className="block text-sm font-bold text-gray-700 mb-1">
                Route
              </label>
              <select
                id="route"
                name="route"
                value={formData.route}
                onChange={handleSelectChange}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                required
              >
                <option value="">Select Route</option>
                {routes.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            {/* Start Date */}
            <div>
              <label htmlFor="FromDate" className="block text-sm font-bold text-gray-700 mb-1">
                From Date
              </label>
              <DatePicker
                selected={formData.From}
                onChange={(date) => handleDateChange(date, "From")}
                dateFormat="dd/MM/yy HH:mm"
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            {/* End Date */}
            <div>
              <label htmlFor="ToDate" className="block text-sm font-bold text-gray-700 mb-1">
                To Date
              </label>
              <DatePicker
                selected={formData.To}
                onChange={(date) => handleDateChange(date, "To")}
                dateFormat="dd/MM/yy HH:mm"
                showTimeSelect
                timeIntervals={15}
                timeFormat="HH:mm"
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>

          <div className="mt-4 text-end">
            <button
              type="submit"
              className="py-2 px-6 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700"
              disabled={loading}
            >
              {loading ? "Loading..." : "Hit Query"}
            </button>
          </div>
        </form>

        {validationError && <p className="text-red-500 text-center mt-4">{validationError}</p>}
        {error && <p className="text-red-500 text-center mt-4">{error}</p>}

        {showGraph && !loading && graphData.length > 0 && (
          <>
            <Graph graphData={graphData} />
            <div className="mt-6">
              
            <div className="flex items-center justify-between mb-4">
    <h2 className="text-xl font-semibold text-gray-800">Anomalies</h2>
    <div className="flex items-center space-x-4">
  <label
    htmlFor="severity"
    className="text-lg font-bold text-gray-700"
  >
    Severity:
  </label>
  <select
    id="severity"
    name="severity"
    value={severity}
    onChange={(e) => setSeverity(e.target.value)}
    className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
  >
    <option value="ALL">ALL</option>
    <option value="ERROR">ERROR</option>
    <option value="INFO">INFO</option>
    <option value="WARN">WARN</option>
  </select>
</div>

  </div>
          
  <table className="min-w-full bg-white border border-gray-200 rounded-md">
  <thead className="bg-gray-300">
    <tr>
      <th className="border-b p-4 text-left font-medium text-gray-700">Timestamp</th>
      <th className="border-b p-4 text-left font-medium text-gray-700">Service</th>
      <th className="border-b p-4 text-left font-medium text-gray-700">Route</th>
      <th className="border-b p-4 text-left font-medium text-gray-700">Anomaly</th>
      <th className="border-b p-4 text-left font-medium text-gray-700">Logs</th>
    </tr>
  </thead>
  <tbody>
    {graphData
     .filter((item) => item.anomaly === "true") //
     .slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)
     .map(({ time, anomaly, service, route }, index) => (
      <tr
        key={index}
        className={`hover:bg-gray-200 ${
          modalOpen && selectedRowIndex === index ? "bg-blue-100" : ""
        }`}
      >
        <td className="border-b p-4">{time}</td>
        <td className="border-b p-4">{service}</td>
        <td className="border-b p-4">{route}</td>
        <td className="border-b p-4">
        
            <span className="text-red-500 font-semibold">True</span>
         
        </td>
        <td className="border-b p-4">
          <button
            onClick={() => handleViewLogs(time, index)}
            className="py-1 px-4 bg-blue-500 text-white rounded hover:bg-blue-600"
            disabled={loadingLogs && selectedRowIndex === index}
          >
            {loadingLogs && selectedRowIndex === index ? "Loading..." : "View Logs"}
          </button>
        </td>
      </tr>
    ))}
  </tbody>
</table>


<Pagination
                currentPage={currentPage}
                totalPages={calculateTotalPages(
                  graphData.filter((item) => item.anomaly === "true").length,
                  rowsPerPage,
                )}
                onPageChange={setCurrentPage}
              />

    

              {graphData.filter((item) => item.anomaly === "true").length === 0 && (
                <p className="text-gray-500 mt-4">No anomalies detected in the data.</p>
              )}
            </div>
          </>
        )}
  {modalOpen && modalData && (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-start z-50 overflow-y-auto">
    <div className="bg-white shadow-lg rounded-lg p-6 w-full max-w-4xl mt-20 mx-4">
      {modalData.logs && Array.isArray(modalData.logs) && (
        <div className="mb-6">
          <table className="min-w-full bg-gray-100 border border-gray-300 rounded-md">
            <thead>
              <tr>
                <th className="border-b p-4 text-left font-medium text-gray-700">Timestamp</th>
                <th className="border-b p-4 text-left font-medium text-gray-700">Message</th>
                <th className="border-b p-4 text-left font-medium text-gray-700">Trace ID</th>
                {/* <th className="border-b p-4 text-left font-medium text-gray-700">More</th> */}
              </tr>
            </thead>
            <tbody>
            {modalData.logs && Array.isArray(modalData.logs) ? (
  modalData.logs
    .slice(
      currentModalPage * rowsPerPageForModal,
      (currentModalPage + 1) * rowsPerPageForModal
    )
    .map((log: any, index: number) => (
                  <tr key={index}>
                    <td className="border-b p-4">{log.timestamp || "N/A"}</td>
                    <td className="border-b p-4">{log.body || "N/A"}</td>
                    <td className="border-b p-4">
                      {log.traceid ? (
                        <button
                          onClick={() => handleViewTraceDetails(log.traceid)}
                          className="text-blue-500 underline"
                        >
                          {log.traceid}
                        </button>
                      ) : (
                        "N/A"
                      )}
                    </td>
                    <td className="border-b p-4">
                    {/* <button
                              onClick={() => toggleLogExpansion(index)}
                              className="text-black-500 text-sm focus:outline-none"
                            >
                              {expandedLogs[index] ? "Collapse ▲" : "Expand ▼"}
                            </button> */}
                      {expandedLogs[index] && (
                        <pre className="p-3 mt-2 bg-white rounded border text-sm text-gray-800 overflow-auto">
                          {JSON.stringify(log, null, 2)}
                        </pre>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center text-gray-500 py-4">
                    No logs found in this time range.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
       
          <Pagination
                    currentPage={currentModalPage}
                    totalPages={calculateTotalPages(modalData.logs.length, rowsPerPageForModal)}
                    onPageChange={setCurrentModalPage}
                  />
                </div>
              )}

         
    

      {modalData.error && <p className="text-red-500 text-center mt-4">{modalData.error}</p>}

      <div className="flex justify-end mt-4">
        <button
          onClick={() => setModalOpen(false)}
          className="py-2 px-6 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}




</div>
      
    </PageLayout>
  );
};

export default AnomalyDetails;
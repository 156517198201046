



import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FaHome, FaChevronDown, FaChartLine, FaLightbulb, FaEye, FaServer, FaExclamationTriangle, FaCoins, FaCog } from "react-icons/fa";
import "../styles/Sidebar.css";

const Sidebar: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [openDropdowns, setOpenDropdowns] = useState<Set<string>>(new Set());
  const navigate = useNavigate();
  const location = useLocation();

  const menuItems = [
    {
      label: "Dashboard",
      path: "/dashboard",
      icon: <FaHome />,
      subItems: null,
    },
    {
      label: "Resources",
      path: "",
      icon: <FaChartLine />,
      subItems: [
        { label: "Active Resource Reports", path: "/resources/active" },
        // { label: 'Cost reports', path: "/resources/costreport" },
        { label: 'Cost List', path: "/resources/costdata" },
      ],
    },
    {
      label: "Monitor Minds",
      path: "",
      icon: <FaServer />,
      subItems: [
        { label: "Monitors", path: "/monitorminds/list" },
        { label: "Status", path: "/monitorminds/status" },
      ],
    },
    {
      label: "Recommendation",
      path: "",
      icon:<FaLightbulb />,
      subItems: [{ label: "Cost Saving", path: "/Recommendation/cost" }],
    },

    {
      label: "Anomaly",
      path: "",
      icon: <FaExclamationTriangle />,
      subItems: [{ label: "Anomaly Details", path: "/Anomaly/anomalydetails" }],
    },

    {
      label: "FinOps",
      path: "",
      icon: <FaCoins />,
      subItems: [
        { label: "FinOpsCost", path: "/Finop/FinopCost" },
        { label: "Cost Allocation", path: "/Finop/CostAllocation" },
      ],
    },
    {
      label: "Observability",
      path: "https://op.cloudbuilders.io/login",
      icon: <FaEye />,
      external: true,
    },
    
    
    {
      label: "Settings",
      path: "",
      icon: <FaCog />,
      subItems: [
        { label: "Organization", path: "/setting/organization" },
        { label: "Quota and Budget", path: "/setting/budget" },
        { label: "Data Source", path: "/setting/DataSource" },
      ],
    },
  ];

  

  useEffect(() => {
    // Open the dropdown for the active submenu item
    const activeParent = menuItems.find(item => 
      item.subItems && item.subItems.some(subItem => location.pathname.startsWith(subItem.path))
    );
    if (activeParent) {
      setOpenDropdowns(prev => new Set(prev).add(activeParent.label));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleNavigate = (path: string, external: boolean = false) => {
    if (external) {
      window.open(path, "_blank");
    } else {
      navigate(path);
    }
  };

  const toggleDropdown = (label: string) => {
    setOpenDropdowns(prev => {
      const newSet = new Set(prev);
      if (newSet.has(label)) {
        newSet.delete(label);
      } else {
        newSet.add(label);
      }
      return newSet;
    });
  };

  const isActive = (path: string) => location.pathname.startsWith(path);

  return (
    <div className={`sidebar ${isExpanded ? "expanded" : "collapsed"}`}>
      <button
        className="toggle-button"
        onClick={() => setIsExpanded(prev => !prev)}
      >
        ☰ 
      </button>
      <ul className="menu">
        {menuItems.map((item, index) => (
          <li key={index}>
            {item.subItems ? (
              <>
                <div
                  className={`menu-item ${item.subItems.some(subItem => isActive(subItem.path)) ? 'active-parent' : ''}`}
                  onClick={() => toggleDropdown(item.label)}
                >
                  {item.icon}
                  {isExpanded && <span>{item.label}</span>}
                  {isExpanded && <FaChevronDown className={openDropdowns.has(item.label) ? "rotate-180" : ""} />}
                </div>
                {openDropdowns.has(item.label) && isExpanded && (
                  <ul className="submenu">
                    {item.subItems.map((subItem, subIndex) => (
                      <li
                        key={subIndex}
                        onClick={() => handleNavigate(subItem.path)}
                        className={`submenu-item ${isActive(subItem.path) ? 'active-link' : ''}`}
                      >
                        {subItem.label}
                      </li>
                    ))}
                  </ul>
                )}
              </>
            ) : (
              <div
                className={`menu-item ${isActive(item.path) ? 'active-link' : ''}`}
                onClick={() => handleNavigate(item.path, !!item.external)}
              >
                {item.icon}
                {isExpanded && <span>{item.label}</span>}
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;


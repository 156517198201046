import React from 'react';

import MySwiper from '../PublicComponent/swiper';

const DemoForm: React.FC = () => {
  
  return (
    <><MySwiper /><div className="min-h-screen flex items-center justify-center bg-black text-white px-4">

      <div className="max-w-7xl w-full grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Left Section */}
        <div>
          <h1 className="text-4xl font-bold mb-6">
            The Future of <br />
            <span className="text-red-500">Observability</span>{" "}
            <span className="text-white">Starts</span> <br />
            Here
          </h1>
          <p className="mb-6 text-gray-300">
            Why wait? Simplify your systems, prevent overspending, and boost performance with a platform designed for today's innovators.
          </p>

          {/* Features Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="flex items-start">
              <span className="w-6 h-6 bg-red-500 text-black font-bold rounded-full flex items-center justify-center mr-3">
                ✓
              </span>
              <p className="text-gray-300">
                <strong>Real-Time Insights</strong>
              </p>
            </div>
            <div className="flex items-start">
              <span className="w-6 h-6 bg-red-500 text-black font-bold rounded-full flex items-center justify-center mr-3">
                ✓
              </span>
              <p className="text-gray-300">
                <strong>Full-Stack Observability</strong>
              </p>
            </div>
            <div className="flex items-start">
              <span className="w-6 h-6 bg-red-500 text-black font-bold rounded-full flex items-center justify-center mr-3">
                ✓
              </span>
              <p className="text-gray-300">
                <strong>AI-Driven Proactive Action</strong>
              </p>
            </div>
            <div className="flex items-start">
              <span className="w-6 h-6 bg-red-500 text-black font-bold rounded-full flex items-center justify-center mr-3">
                ✓
              </span>
              <p className="text-gray-300">
                <strong>Complete Cost Control</strong>
              </p>
            </div>
          </div>

          {/* What Happens Next Section */}
          <div style={{ borderTop: "1px solid rgba(229, 62, 62, 0.56)" }}>
            <h2 className="font-bold mt-3 mb-4">What Happens Next?</h2>
            <div className="flex flex-wrap items-center text-gray-300">
              <span className="mr-3" style={{ maxWidth: "113px" }}>
                Schedule a call <span className="text-white">at your time</span>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                className="mr-3"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.3335 1.96289L21.2967 11.9997L4.3335 22.0444V1.96289ZM6.3335 5.47012V18.5357L17.3703 12.0004L6.3335 5.47012Z"
                  fill="#E53E3E"
                  fillOpacity="0.56" />
              </svg>

              <span className="mr-3" style={{ maxWidth: "113px" }}>
                Assisted demo <span className="text-white">for your needs</span>
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                className="mr-3"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4.3335 1.96289L21.2967 11.9997L4.3335 22.0444V1.96289ZM6.3335 5.47012V18.5357L17.3703 12.0004L6.3335 5.47012Z"
                  fill="#E53E3E"
                  fillOpacity="0.56" />
              </svg>

              <span className="mr-3" style={{ maxWidth: "113px" }}>
                Get started for <span className="font-bold text-white">FREE</span>
              </span>
            </div>
          </div>
        </div>

        {/* Right Section */}
        <div className="bg-white text-black p-8 rounded-lg shadow-lg">
          <h2 className="text-2xl font-bold mb-6 text-center">Schedule a Demo</h2>
          <form className="space-y-4">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="firstName"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  placeholder="Enter first name" />
              </div>
              <div>
                <label htmlFor="lastName" className="block text-sm font-medium">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  id="lastName"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  placeholder="Enter last name" />
              </div>
            </div>
            <div>
              <label htmlFor="company" className="block text-sm font-medium">
                Company/Organisation <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="company"
                className="w-full border border-gray-300 rounded-md px-3 py-2"
                placeholder="Enter company/organisation" />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="email" className="block text-sm font-medium">
                  Company Email ID <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  placeholder="Enter email" />
              </div>
              <div>
                <label htmlFor="contact" className="block text-sm font-medium">
                  Contact Number
                </label>
                <input
                  type="text"
                  id="contact"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  placeholder="Enter contact number" />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label htmlFor="role" className="block text-sm font-medium">
                  Your Role
                </label>
                <input
                  type="text"
                  id="role"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                  placeholder="Enter role" />
              </div>
              <div>
                <label htmlFor="slot" className="block text-sm font-medium">
                  Preferred Slot <span className="text-red-500">*</span>
                </label>
                <select
                  id="slot"
                  className="w-full border border-gray-300 rounded-md px-3 py-2"
                >
                  <option>Select slot</option>
                  <option>30 minutes</option>

                </select>
              </div>
            </div>
            <div>
              <label htmlFor="message" className="block text-sm font-medium">
                Message
              </label>
              <textarea
                id="message"
                rows={4}
                className="w-full border border-gray-300 rounded-md px-3 py-2"
                placeholder="To better assist you, you can add any additional note."
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full bg-black text-white font-medium py-2 rounded-md hover:bg-gray-800"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </div></>
  );
};

export default DemoForm;

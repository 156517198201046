
import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import * as echarts from "echarts";

interface BarGraphProps {
  chartData: {
  accrued_at : string[];
  total_amount: number[];
  };
  totalAmount: number | null;
}


const BarGraph: React.FC<BarGraphProps> = ({ chartData, totalAmount }) => {
  const chartRef = useRef<HTMLDivElement>(null);
  
  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);

      // console.log('chartData', chartData);

      const chartOptions = {
        tooltip: {
          trigger: "axis",
        },
        xAxis: {
          type: "category",
          data: chartData.accrued_at,
          name: "Date",
          axisLabel: {
            rotate: 45,
          },
        },
        yAxis: {
          type: "value",
          name: "Cost (USD)",
        },
        series: [
          {
            data: chartData.total_amount,
            type: "bar",
            name: "Daily Cost",
            itemStyle: {
              color: "#3a85ff",
            },
          },
          ...(totalAmount !== null
            ? [
                {
                  type: "line",
                  name: "Total Amount",
                  data: [],
                  markLine: {
                    symbol: "none",
                    label: {
                      formatter: `Total Amount: ${totalAmount}`,
                      position: "end",
                      color: "#ff0000",
                    },
                    lineStyle: {
                      type: "dashed",
                      color: "#ff0000",
                    },
                    data: [
                      {
                        yAxis: totalAmount,
                      },
                    ],
                  },
                },
              ]
            : []),
        ],
        legend: {
          data: ["Daily Cost", ...(totalAmount !== null ? ["Total Amount"] : [])],
        },
      };

      chart.setOption(chartOptions);

      // Dispose the chart instance on cleanup
      return () => {
        chart.dispose();
      };
    }
  }, [chartData, totalAmount]);

  return (
    <Box mt={4}>
      <div ref={chartRef} style={{ height: "400px", width: "100%" }} />
    </Box>
  );
};

export default BarGraph;

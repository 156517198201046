import React from "react";
import { Box, Grid, Typography, Card, CardContent, CircularProgress } from "@mui/material";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { TitleComponent, TooltipComponent, LegendComponent } from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";
import ReactECharts from "echarts-for-react";
import Analysis from "../../PublicComponent/Analysis";

echarts.use([TitleComponent, TooltipComponent, LegendComponent, BarChart, CanvasRenderer]);

const cashFlowData = {
  xAxis: {
    type: "category",
    data: ["Jan", "Feb", "Mar"],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      name: "Cash Going In",
      type: "bar",
      data: [4000, 8000, 12000, 9000, 7000, 10000, 11000, 8000, 9000, 8500, 9500, 11000],
      itemStyle: { color: "#4caf50" },
    },
    {
      name: "Cash Going Out",
      type: "bar",
      data: [3000, 7000, 11000, 8500, 6000, 9000, 10000, 7500, 8500, 8000, 9000, 10000],
      itemStyle: { color: "#f44336" },
    },
  ],
};

const profitLossData = {
  xAxis: {
    type: "category",
    data: ["Jan", "Feb"],
  },
  yAxis: {
    type: "value",
  },
  series: [
    {
      name: "Profit / Loss",
      type: "bar",
      data: [1000, 1500, -500, 1000, 200, -300, 800, -400, 500, -300, 1000, -700],
      itemStyle: { color: "#4caf50" },
    },
  ],
};

const CashFlow = () => {
  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h5" gutterBottom sx={{ fontWeight: "bold" }}>
        Cash Flow Overview
      </Typography>

      <Grid container spacing={3}>
        {/* Left Sidebar: Dashboard */}
        <Grid item xs={12} md={3}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>Dashboard</Typography>
              <Typography variant="subtitle1" sx={{ mt: 2 }}>
                1. What-If Analysis
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                2. Upload Existing Tracker
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                3.New  Project Tracker
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                4. Error And integration
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                5.Setting
              </Typography>
              <Typography variant="subtitle1" sx={{ mt: 1 }}>
                6. Admin
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Right Side: Cash Flow Metrics and Charts */}
        <Grid item xs={12} md={9}>
          {/* Top Metrics */}
          <Grid container spacing={3}>
            <Grid item xs={6} md={2}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>ForeCost</Typography>
                  <Typography variant="h6">4,521.00</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={2}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>In Flow</Typography>
                  <Typography variant="h6">93,437.00</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={2}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle2"   sx={{ fontWeight: "bold" }}>Out Flow</Typography>
                  <Typography variant="h6">97,131.00</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={2}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle2" sx={{ fontWeight: "bold" }}>In Flow / Out Flow</Typography>
                  <Typography variant="h6" color="error">
                    -3,694.00
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} md={2}>
              <Card>
                <CardContent>
                  <Typography variant="subtitle2"  sx={{ fontWeight: "bold" }}>Ending Cash on Hand</Typography>
                  <Typography variant="h6">827.00</Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>

          {/* Bar Chart */}
          <Box mt={4}>
            <Typography variant="h6">Cash Flow</Typography>
            <ReactECharts option={cashFlowData} style={{ height: "400px" }} />
          </Box>

          {/* Profit / Loss and Accounts */}
          <Grid container spacing={3} mt={4}>
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <Typography variant="h6">In Flow / Out Flow</Typography>
                  <ReactECharts option={profitLossData} style={{ height: "400px" }} />
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Accounts Receivable</Typography>
                  <Typography variant="h4">8,542.00</Typography>
                  <Box mt={2} display="flex" alignItems="center">
                    <CircularProgress
                      variant="determinate"
                      value={92}
                      size={60}
                      sx={{ color: "#4caf50" }}
                    />
                    <Typography variant="h6" sx={{ marginLeft: 2 }}>
                      92% Received
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={6} md={3}>
              <Card>
                <CardContent>
                  <Typography variant="h6">Accounts Payable</Typography>
                  <Typography variant="h4">4,162.00</Typography>
                  <Box mt={2} display="flex" alignItems="center">
                    <CircularProgress
                      variant="determinate"
                      value={96}
                      size={60}
                      sx={{ color: "#f44336" }}
                    />
                    <Typography variant="h6" sx={{ marginLeft: 2 }}>
                      96% Paid
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Analysis />
        </Grid>
      </Grid>
    </Box>
  );
};

export default CashFlow;

import React from 'react';
import { Card, CardContent, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import PageLayout from '../../../components/PageLayout';

const dataSources = [
  {
    name: "Ali dev",
    type: "Alibaba Cloud",
    resourcesCharged: 0,
    expensesUpToDate: 0,
    expensesForecast: 10944.38,
    icon: "/placeholder.svg?height=20&width=20"
  },
  {
    name: "AWS HQ",
    type: "AWS",
    resourcesCharged: 0,
    expensesUpToDate: 0,
    expensesForecast: 50153.38,
    icon: "/placeholder.svg?height=20&width=20"
  },
  {
    name: "AWS Marketing",
    type: "AWS",
    resourcesCharged: 0,
    expensesUpToDate: 0,
    expensesForecast: 14172.55,
    icon: "/placeholder.svg?height=20&width=20"
  },
  {
    name: "Azure QA",
    type: "Azure subscription",
    resourcesCharged: 0,
    expensesUpToDate: 0,
    expensesForecast: 5354.55,
    icon: "/placeholder.svg?height=20&width=20"
  },
  {
    name: "Dev environment",
    type: "Azure subscription",
    resourcesCharged: 0,
    expensesUpToDate: 0,
    expensesForecast: 22043.17,
    icon: "/placeholder.svg?height=20&width=20"
  },
  {
    name: "Environment",
    type: "Environment",
    resourcesCharged: 0,
    expensesUpToDate: 0,
    expensesForecast: 1348.72,
    icon: "/placeholder.svg?height=20&width=20"
  }
];

function DataSource() {
  return (
    <PageLayout>
      <div style={{ padding: '24px', height: '100vh', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h4" gutterBottom>
          Data Sources
        </Typography>
        
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="body2" color="textSecondary">Total expenses month-to-date</Typography>
                <Typography variant="h6" gutterBottom>$0</Typography>
                <Typography variant="body2" color="green">Less than for previous month</Typography>
                <Typography variant="h5" color="green">100%</Typography>
              </CardContent>
            </Card>
          </Grid>
          
          <Grid item xs={12} md={6} lg={3}>
            <Card>
              <CardContent>
                <Typography variant="body2" color="textSecondary">Forecast for this month</Typography>
                <Typography variant="h6" gutterBottom>$139.24k</Typography>
                <Typography variant="body2" color="green">Less than for previous month</Typography>
                <Typography variant="h5" color="green">6%</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        
        <TableContainer component={Card} style={{ marginTop: '24px' }}>
          <Table>
            <TableHead className="bg-gray-300">
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Resources charged (this month)</TableCell>
                <TableCell>Expenses up-to-date (this month)</TableCell>
                <TableCell>Expenses forecast (this month)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataSources.map((source) => (
                <TableRow key={source.name}>
                  <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <span style={{ marginLeft: '8px' }}>{source.name}</span>
                    </div>
                  </TableCell>
                  <TableCell>{source.type}</TableCell>
                  <TableCell>{source.resourcesCharged}</TableCell>
                  <TableCell>${source.expensesUpToDate}</TableCell>
                  <TableCell>${source.expensesForecast.toLocaleString('en-US', { minimumFractionDigits: 2 })}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </PageLayout>
  );
}

export default DataSource;

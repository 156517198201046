


import { useEffect, useRef, useState } from 'react';
import PageLayout from '../../../components/PageLayout';
import { createApiClient } from "../../../modules/services/axios";
import { useTokens } from "../../../modules/services/token";

interface Resource {
  resource_id: string;
  token: string;
  recommendation_actions: {
    action: string;
    description: string;
    potential_savings: number;
  }[];
}

interface ModalProps {
  isOpen: boolean;
  resource: Resource | null;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, resource, onClose }) => {
  if (!isOpen || !resource) return null;

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full">
        <h2 className="text-xl font-semibold mb-4">Resource Details</h2>
        <p><strong>Resource ID:</strong> {resource.resource_id}</p>
        {resource.recommendation_actions.map((action, index) => (
          <div key={index} className="mt-2">
            <p><strong>Action:</strong> {action.action}</p>
            <p><strong>Description:</strong> {action.description}</p>
            <p><strong>Potential Savings:</strong> ${action.potential_savings.toFixed(2)}</p>
          </div>
        ))}
        <button onClick={onClose} className="mt-4 px-4 py-2 bg-blue-500 text-white rounded-md">
          Close
        </button>
      </div>
    </div>
  );
};

const Resources: React.FC = () => {
  const [resources, setResources] = useState<Resource[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedResource, setSelectedResource] = useState<Resource | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { accessToken, idToken } = useTokens(); // Fetch tokens
  const hasFetchedData = useRef(false); // Prevent multiple API calls

  // Use URLSearchParams to get the token from the URL
  const token = new URLSearchParams(window.location.search).get('token');

  useEffect(() => {
     const apiClient = createApiClient();
   
    if (token) {
      const fetchResources = async () => {
        if (hasFetchedData.current || !accessToken || !idToken) return; // Prevent duplicate calls or missing tokens
      hasFetchedData.current = true;

        try {
          const response = await apiClient.get(`/api/vantage/recommendations/${token}/resources`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              id_Token: idToken,
            },
          });
          console.log('Fetched data:', response.data);

          if (response.data.resources) {
            setResources(response.data.resources);
          } else {
            setError('No resources found in the response');
          }
        } catch (error) {
          setError('Error fetching resource data: ' + (error instanceof Error ? error.message : 'Unknown error'));
        } finally {
          setLoading(false);
        }
      };

      fetchResources();
    } else {
      setLoading(false);
    }
  }, [token, accessToken, idToken]);


  const handleResourceClick = (resource: Resource) => {
    setSelectedResource(resource);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedResource(null);
  };

  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-100 flex flex-col md:flex-row"> {/* Light gray background for the whole page */}
        <div className="flex-1 p-3 md:p-6 bg-gray-50 rounded-lg shadow-lg"> {/* Light gray background for content */}
          <h2 className="text-3xl font-semibold mb-4">Affected Resources</h2>

          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p className="text-red-500">{error}</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-50 border border-gray-200 rounded-lg">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Resource ID</th>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Action</th>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Description</th>
                    <th className="px-4 py-2 text-left !text-lg text-gray-700">Potential Savings</th>
                  </tr>
                </thead>
                <tbody>
                  {resources.map((resource, index) => (
                    resource.recommendation_actions.map((action, actionIndex) => (
                      <tr key={`${index}-${actionIndex}`} className="border-t">
                        <td
                          className="px-4 py-2 text-blue-500 cursor-pointer"
                          onClick={() => handleResourceClick(resource)} // Open the modal on click
                        >
                          {resource.resource_id}
                        </td>
                        <td className="px-4 py-2">{action.action}</td>
                        <td className="px-4 py-2">{action.description}</td>
                        <td className="px-4 py-2 text-green-600">
                          ${action.potential_savings.toFixed(2)}
                        </td>
                      </tr>
                    ))
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Modal to display resource details */}
          <Modal isOpen={isModalOpen} resource={selectedResource} onClose={closeModal} />
        </div>
      </div>
    </PageLayout>
  );
};

export default Resources;





import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import {
  FaEdit,
  FaTrash,
  FaPlus,
  FaArrowUp,
  FaArrowDown,
} from "react-icons/fa";
import PageLayout from "../../../components/PageLayout";
import { createApiClient } from "../../../modules/services/axios";
import axios from "axios";
import { useTokens } from "../../../modules/services/token";


interface MonitorData {
  // id: string;
  friendly_name: string;
  interval: number;
  monitor_id: string;
  status: number;
  url: string;
}

const List: React.FC = () => {
  const [data, setData] = useState<MonitorData[]>([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentMonitor, setCurrentMonitor] = useState<MonitorData | null>(
    null
  );
  const [monitorName, setMonitorName] = useState("");
  const [monitorUrl, setMonitorUrl] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const { accessToken,idToken } = useTokens(); // Access the tokens from the hook

// prevent multiple API calls
const hasFetchedData = useRef(false);
  useEffect(() => {
    if (!accessToken || !idToken)
      return;
    const fetchData = async () => {
      if (hasFetchedData.current) return; // Prevent duplicate calls
      hasFetchedData.current = true;
      try {
          const apiClient = createApiClient();
        const response = await apiClient.get(`/api/uptime/monitor`, {
          headers: {
            Authorization: `Bearer ${accessToken}`, // Use the idToken here
            id_Token: idToken
 
          },
        });

     
        setData(Array.isArray(response.data.monitors) ? response.data.monitors : []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [accessToken, idToken]);

  const getStatusLabel = (status: number) => {
    switch (status) {
      case 1:
        return "Preparing...";
      case 0:
        return "Paused";
      case 2:
        return <FaArrowUp className="text-green-500" />;
      case 9:
        return <FaArrowDown className="text-red-500" />;
      default:
        return "-";
    }
  };

  const handleDelete = async (monitorId: string) => {
    if (!idToken || !accessToken) return;
    console.log("Deleting monitor with ID:", monitorId);

    if (isNaN(Number(monitorId))) {
      alert("Invalid monitor ID");
      return;
    }

    const isConfirmed = window.confirm(
      "Are you sure you want to delete this monitor?"
    );
    if (!isConfirmed) return;

    try {
       const apiClient = createApiClient();
      const response = await apiClient.delete(`/api/uptime/monitor/${monitorId}`,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });

  

      if (response.status === 200) {
        setData(data.filter((monitor) => monitor.monitor_id !== monitorId));
        alert("Monitor deleted successfully");
      } else if (response.data.error && response.data.error.message === "monitor not found.") {
        alert("Monitor not found.");
      } else {
        alert("Failed to delete monitor");
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error deleting monitor:", error.message);
        alert("Error deleting monitor: " + error.message);
      } else {
        console.error("Unknown error occurred:", error);
        alert("An unknown error occurred.");
      }
    }
  };

  const handleEdit = (monitor: MonitorData) => {
    setMonitorName(monitor.friendly_name);
    setMonitorUrl(monitor.url);
    setCurrentMonitor(monitor);
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    if (!currentMonitor) return;
    setIsUpdating(true);
    setUpdateSuccess(false);

    try {
     const apiClient = createApiClient();
      const response = await apiClient.put(`/api/uptime/monitor/${currentMonitor.monitor_id}`, {
        monitor_url: monitorUrl,
        friendly_name: monitorName,
        interval: currentMonitor.interval,
      }, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });

      console.log("Update Result:", response.data);

      if (response.status === 200) {
        setData(
          data.map((monitor) =>
            monitor.monitor_id === currentMonitor.monitor_id
              ? { ...monitor, friendly_name: monitorName, url: monitorUrl }
              : monitor
          )
        );
        setUpdateSuccess(true);
        setTimeout(() => {
          setUpdateSuccess(false); // Reset success message after a short delay
        }, 2000);
      } else {
        alert(
          "Failed to update monitor: " +
          (response.data.message || response.data.error || "Unknown error")
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Error updating monitor:", error.message);
        alert("Error updating monitor: " + error.message);
      } else {
        console.error("Unknown error occurred:", error);
        alert("An unknown error occurred.");
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setMonitorName("");
    setMonitorUrl("");
  };

  return (
    <PageLayout>
      <div className="flex flex-col min-h-screen bg-gray-100">
        <div className="flex-1 p-4 container mx-auto">
          <h1 className="text-3xl font-semibold mb-4 text-start text-gray-700">
            Monitors
          </h1>

          <div className="flex justify-end mb-4">
            <Link to="/monitorminds/create">
              <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 flex items-center">
                <FaPlus className="mr-2" /> Create Monitor
              </button>
            </Link>
          </div>

          {loading ? (
            <p className="text-center text-gray-500">Loading...</p>
          ) : data.length === 0 ? (
            <p className="text-center text-gray-500">No data available</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="w-full border border-gray-300 bg-white rounded-lg shadow-md">
                <thead className="bg-gray-400">
                  <tr>
                    <th className="p-3 text-left !text-lg text-gray-700">
                      Monitor Name
                    </th>
                    <th className="p-3 text-left !text-lg text-gray-700">
                      Monitor URL
                    </th>
                    <th className="p-3 text-left !text-lg text-gray-700">
                      Status
                    </th>
                    <th className="p-3 text-left !text-lg text-gray-700">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((monitor, index) => (
                    <tr key={index} className="border-b last:border-0  border-gray-300">
                      <td className="p-3 text-gray-800">
                        {monitor.friendly_name}
                      </td>
                      <td className="p-3 text-blue-600 hover:underline">
                        <a
                          href={monitor.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {monitor.url}
                        </a>
                      </td>
                      <td className="p-3 text-gray-800">
                        {getStatusLabel(monitor.status)}
                      </td>
                      <td className="p-3">
                        <button
                          onClick={() => handleEdit(monitor)}
                          className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-yellow-600 mr-2"
                        >
                          <FaEdit />
                        </button>
                        <button
                          onClick={() => handleDelete(monitor.monitor_id)}
                          className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-red-600"
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        {isModalOpen && currentMonitor && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center p-4">
            <div
              className={`bg-white p-6 rounded-lg w-full max-w-md transform transition-transform ${isUpdating ? "scale-95" : "scale-100"
                }`}
            >
              <h2 className="text-xl font-semibold mb-4">Edit Monitor</h2>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Monitor Name</label>
                <input
                  type="text"
                  value={monitorName}
                  onChange={(e) => setMonitorName(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                  disabled={isUpdating}
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Monitor URL</label>
                <input
                  type="text"
                  value={monitorUrl}
                  onChange={(e) => setMonitorUrl(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                  disabled={isUpdating}
                />
              </div>

              <div className="flex justify-end">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 bg-gray-400 text-white rounded-lg hover:bg-gray-500 mr-2"
                  disabled={isUpdating}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdate}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300 flex items-center"
                  disabled={isUpdating}
                >
                  {isUpdating ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v8H4z"
                      ></path>
                    </svg>
                  ) : (
                    "Update"
                  )}
                </button>
              </div>

              {updateSuccess && (
                <p className="mt-4 text-center text-green-600">
                  Monitor updated successfully!
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default List;


import React, { useEffect, useState } from "react"
import PageLayout from "../../../components/PageLayout"
import { useTokens } from "../../../modules/services/token"
import { createApiClient } from "../../../modules/services/axios"
import { Card, CardContent, Grid, Typography } from "@mui/material"


type Entry = {
  id: string
  name: string
  resource_group_id?: string
  children?: Entry[]
  total_usage?: number
  total_credits?: number
  total_cost?: number
  total_tax?:number
  resource_costs?: Record<
    string,
    {
      total_usage: number
      total_credits: number
      total_cost: number
      total_tax:number
    }
  >
  unallocated_resources?: {
    total_usage: number
    total_credits: number
    total_cost: number
    total_tax: number
  }
}

const CostAllocation: React.FC = () => {
  const [data, setData] = useState<Entry[] | null>(null)
  const [expanded, setExpanded] = useState<Record<string, boolean>>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [totalCost, setTotalCost] = useState<{
    total_usage: number
    total_credits: number
    total_cost: number
    total_tax:number
  } | null>(null)
  const { accessToken, idToken } = useTokens()
  const apiClient = createApiClient()
  const getYearStartDateRange = () => {
    const endDate = new Date(); // Today's date
    const startDate = new Date(endDate.getFullYear(), 0, 2); // January 1st of the current year

    const formatDate = (date: Date) => date.toISOString().split("T")[0]
    return { startDate: formatDate(startDate), endDate: formatDate(endDate) }
  }

  useEffect(() => {
    const fetchHierarchyData = async () => {
      if (!accessToken || !idToken) return

      const { startDate, endDate } = getYearStartDateRange(); // Use the updated functio

      try {
        const structureResponse = await apiClient.get(`api/finops/resource_hierarchy`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        })
        const structureData = structureResponse.data.hierarchy_details.hierarchies

        const costResponse = await apiClient.get(
          `/api/finops/total_cost_by_hierarchy?start_date=${startDate}&end_date=${endDate}&hierarchy_id=hierarchy_123`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              id_Token: idToken,
            },
          },
        )
        const costData = costResponse.data.hierarchy.hierarchy

        const mergedData = mergeCostDataWithStructure(structureData, costData)
          // Calculate total cost for organization
          const organizationTotalCost = calculateTotalCost(costData)
          setTotalCost(organizationTotalCost)

        setData(mergedData)
      } catch (error) {
        console.error("Error fetching data:", error)
      } finally {
        setLoading(false)
      }
    }

    fetchHierarchyData()
  }, [accessToken, idToken])

  const mergeCostDataWithStructure = (structure: Entry[], costData: Entry): Entry[] => {
    const updateEntry = (entry: Entry, costEntry: Entry): Entry => {
      if (entry.id === costEntry.id) {
        const updatedEntry: Entry = {
          ...entry,
          total_usage: costEntry.total_usage,
          total_credits: costEntry.total_credits,
          total_cost: costEntry.total_cost,
          total_tax: costEntry.total_tax,
          resource_costs: costEntry.resource_costs,
          children: entry.children?.map((child) => {
            const correspondingChildCost = costEntry.children?.find(
              (costChild) => costChild.id === child.id
            );
            return correspondingChildCost ? updateEntry(child, correspondingChildCost) : child;
          }),
        };

        // Add unallocated_resources as a child if present
        if (costEntry.unallocated_resources) {
          const unallocatedChild: Entry = {
            id: "unallocated_resources",
            name: "Unallocated Resources",
            total_usage: costEntry.unallocated_resources.total_usage,
            total_credits: costEntry.unallocated_resources.total_credits,
            total_cost: costEntry.unallocated_resources.total_cost,
            total_tax: costEntry.unallocated_resources.total_tax,
            resource_costs: undefined,
            children: undefined,
          };

          updatedEntry.children = [...(updatedEntry.children || []), unallocatedChild];
        }

        return updatedEntry;
      }

      return entry;
    };

    const findAndUpdateEntry = (entries: Entry[], costEntry: Entry): Entry[] => {
      return entries.map((entry) => {
        if (entry.id === costEntry.id) {
          return updateEntry(entry, costEntry);
        }
        if (entry.children) {
          return {
            ...entry,
            children: findAndUpdateEntry(entry.children, costEntry),
          };
        }
        return entry;
      });
    };

    return findAndUpdateEntry(structure, costData);
  };
  const calculateTotalCost = (costData: Entry): {
    total_usage: number
    total_credits: number
    total_cost: number
    total_tax:number
  } => {
    const { total_usage, total_credits, total_cost,total_tax } = costData
    return {
      total_usage: total_usage || 0,
      total_credits: total_credits || 0,
      total_cost: total_cost || 0,
      total_tax:total_tax || 0
    }
  }

  const toggleExpand = (id: string) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }))
  }

  const renderHierarchy = (entries: Entry[], depth = 0) =>
    entries.map((entry) => (
      <React.Fragment key={entry.id}>
        <tr className="hover:bg-gray-50 border-t border-gray-200">
          {/* Name Column */}
          <td className="px-4 py-3">
            <div className="flex items-center" style={{ paddingLeft: `${depth * 24}px` }}>
            {(entry.children || entry.resource_costs ) && (
                <button
                  onClick={() => toggleExpand(entry.id)}
                  className="mr-2 w-4 h-4 flex items-center justify-center text-gray-500"
                >
                  <svg className="w-3 h-3" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="3">
                    <path d={expanded[entry.id] ? "M19 9l-7 7-7-7" : "M9 5l7 7-7 7"} />
                  </svg>
                </button>
              )}
              <span className={`text-sm font-medium ${depth > 0 ? "text-gray-600" : "text-black"}`}>{entry.name}</span>
            </div>
          </td>

          {/* Total Cost Without Discounts */}
          <td className="px-4 py-3">
            {entry.total_credits ? `$${entry.total_credits.toFixed(2)}` : "-"}
          </td>

          {/* Total Cost With Discounts */}
          <td className="px-4 py-3">
            {entry.total_usage ? `$${entry.total_usage.toFixed(2)}` : "-"}
          </td>

          {/* Total Discounts */}
          <td className="px-4 py-3">{entry.total_cost ? `$${entry.total_cost.toFixed(2)}` : "-"}</td>

          <td className="px-4 py-3">{entry.total_tax ? `$${entry.total_tax.toFixed(2)}` : "-"}</td>
        </tr>

        

        {/* Render resource costs if expanded */}
        {expanded[entry.id] &&
          entry.resource_costs &&
          Object.entries(entry.resource_costs).map(([resourceId, resource]) => (
            <tr key={resourceId} className="hover:bg-gray-50 border-t border-gray-200">
              <td className="px-4 py-3">
                <div className="flex items-center text-gray-500" style={{ paddingLeft: `${(depth + 1) * 24}px` }}>
                  - {resourceId}
                </div>
              </td>

              <td className="px-4 py-3">
                {resource.total_credits ? `$${resource.total_credits.toFixed(2)}` : "-"}
              </td>

              <td className="px-4 py-3">
                {resource.total_usage ? `$${resource.total_usage.toFixed(2)}` : "-"}
              </td>

              <td className="px-4 py-3">
                {resource.total_cost ? `$${resource.total_cost.toFixed(2)}` : "-"}
              </td>

              <td className="px-4 py-3">
                {resource.total_tax ? `$${resource.total_tax.toFixed(2)}` : "-"}
              </td>
            </tr>
          ))}

        {/* Render children recursively if expanded */}
        {expanded[entry.id] && entry.children && renderHierarchy(entry.children, depth + 1)}
      </React.Fragment>
    ))

  const renderLoadingSpinner = () => (
    <div className="flex justify-center items-center h-full">
      <div className="w-12 h-12 border-4 border-blue-500 border-solid border-t-transparent rounded-full animate-spin"></div>
    </div>
  )

  return (
    <PageLayout>
      <div className="h-screen bg-gray-100 p-4 flex flex-col overflow-auto height-100">
      <div className="flex items-center mb-4">
      <h1 className="text-2xl font-bold">
    $ Cost Allocation
    <Typography
      variant="body1"
      style={{ fontWeight: "bold", display: "inline", marginLeft: "8px" }}
      color="black"
    >
      {`(${new Date(new Date().getFullYear(), 0, 1).toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })} - ${new Date(new Date().setDate(new Date().getDate() - 1)).toLocaleDateString(undefined, {
        month: "short",
        day: "numeric",
        year: "numeric",
      })})`}
    </Typography>
  </h1>
</div>
      <div className="bg-white rounded-lg shadow overflow-hidden mb-4">
        
          {totalCost && (
            
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardContent>
             
                    <Typography variant="h6" gutterBottom>
                      Total Credits
                    </Typography>
                    
                    <Typography variant="h5">
                      ${totalCost.total_credits.toFixed(2)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardContent>
           
                    <Typography variant="h6" gutterBottom>
                      Total Usage
                    </Typography>
                    <Typography variant="h5">
                      ${totalCost.total_usage.toFixed(2)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardContent>
     
                    <Typography variant="h6" gutterBottom>
                      Total Cost
                    </Typography>
                    <Typography variant="h5">
                      ${totalCost.total_cost.toFixed(2)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Card>
                  <CardContent>
      
                    <Typography variant="h6" gutterBottom>
                      Total Tax
                    </Typography>
                    <Typography variant="h5">
                      ${totalCost.total_tax.toFixed(2)}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          )}
        </div>
        <div className="bg-white rounded-lg shadow overflow-hidden  ">
          {loading ? (
            renderLoadingSpinner()
          ) : (
            <div
        className="overflow-x-auto overflow-y-auto h-full"
        style={{ maxHeight: "100%", maxWidth: "none" }}
      >
            <table className="w-full">
              <thead>
                <tr className="border-y bg-gray-300 border-gray-100">
                  <th className="px-4 py-3 text-left font-bold text-black-600">Name</th>
                  <th className="px-4 py-3 text-left font-bold text-black-600">Total Credits</th>
                  <th className="px-4 py-3 text-left font-bold text-black-600">Total Usage</th>
                  <th className="px-4 py-3 text-left font-bold text-black-600">Total Cost</th>
                  <th className="px-4 py-3 text-left font-bold text-black-600">Total Tax</th>
                </tr>
              </thead>
              <tbody>{data && renderHierarchy(data)}</tbody>
            </table>
       </div>
          )}
        </div>
      </div>
    </PageLayout>
  )
}

export default CostAllocation


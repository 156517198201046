import { Auth0Provider } from "@auth0/auth0-react";
import React, { PropsWithChildren, useMemo } from "react";

interface Auth0ProviderWithNavigateProps {
  children: React.ReactNode;
}

export const Auth0ProviderWithNavigate = ({
  children,
}: PropsWithChildren<Auth0ProviderWithNavigateProps>): JSX.Element | null => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN || "";
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID || "";
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const scope = process.env.REACT_APP_AUTH0_SCOPE;
  
  const urlParams = new URLSearchParams(window.location.search);
  const invitation = urlParams.get("invitation");
  const organization = urlParams.get("organization");
  const organizationName = urlParams.get("organization_name");

  const redirectUri = useMemo(() => {
    let uri = window.location.origin;
    if (invitation && organization) {
      uri += `/?invitation=${invitation}&organization=${organization}`;
    }
    return uri;
  }, [invitation, organization]);

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience,
        scope,
        ...(invitation && organization
          ? {
              organization,
              invitation,
            }
          : {}),
      }}
    >
      {children}
    </Auth0Provider>
  );
};

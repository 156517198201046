


import React, { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import PageLayout from '../../components/PageLayout';
import { createApiClient } from '../../modules/services/axios';
import { useTokens } from '../../modules/services/token';

interface ResourceReport {
  title: string;
  created_at: string;
  
}

interface Monitor {
  friendly_name: string;
  url: string;
  status: number;
}

interface StatusPage {
  friendly_name: string;
  standard_url: string;
  custom_url?: string;
}

interface Recommendation {
  service: string;
  potential_savings: string;
  description: string;
}

const Dashboard: React.FC = () => {
   const { accessToken,idToken } = useTokens(); // Access the tokens from the hook
  const { user, isLoading: authLoading } = useAuth0();
  const [resourceReports, setResourceReports] = useState<ResourceReport[]>([]);
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [statusPages, setStatusPages] = useState<StatusPage[]>([]);
  const [recommendations, setRecommendations] = useState<Recommendation[]>([]);
  const [, setLoading] = useState(true);

  const apiClient = createApiClient();

  useEffect(() => {
    if (authLoading || !user) return;
    

    const fetchData = async () => {
      try {
        if (!idToken || !accessToken) return;
        // const token = "rprt_926ecb62836ada76";

        // Fetch Resource Reports with axios
        const resourceReportsRes = await apiClient.get(`/api/vantage/resource_reports`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setResourceReports(resourceReportsRes.data.resource_reports || []);
        

        // Fetch Monitors with axios
        const monitorsRes = await apiClient.get(`/api/uptime/monitor`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setMonitors(monitorsRes.data.monitors || []);

        // Fetch Status Pages with axios
        const statusPagesRes = await apiClient.get(`/api/uptime/status`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setStatusPages(statusPagesRes.data.psps || []);

        // Fetch Recommendations with axios
        const recommendationsRes = await apiClient.get(`/api/vantage/recommendations`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setRecommendations(recommendationsRes.data.recommendations || []);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  },  [accessToken,idToken]);

  // Calculate totals
  const totalPotentialSavings = recommendations.reduce(
    (sum, rec) => sum + parseFloat(rec.potential_savings.replace('$', '')) ,
    0
  );
  const totalMonitors = monitors.length;
  const totalStatusPages = statusPages.length; // Correctly fetch total number of status pages
  const totalResourceReports = resourceReports.length;
  const totalRecommendations = recommendations.length;

  const upCount = monitors.filter((monitor) => monitor.status === 2).length;
  const downCount = monitors.filter((monitor) => monitor.status === 9).length;


  return (
    <PageLayout>
      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 p-4 w-full h-screen bg-gray-100">
          <h1 className="text-2xl font-semibold mb-6">Dashboard</h1>

          {/* Total Metrics */}
          <section className="mb-8 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-6">
            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">Potential Savings</h3>
              <p className="text-2xl">${totalPotentialSavings.toFixed(2)}</p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">Monitors</h3>
              <p className="text-2xl">
                {totalMonitors} - <span className="text-green-500">{upCount} Up</span> / <span className="text-red-500">{downCount} Down</span>
              </p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">Status Pages</h3>
              <p className="text-2xl">{totalStatusPages}</p> {/* Display total status pages here */}
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">Resource Reports</h3>
              <p className="text-2xl">{totalResourceReports}</p>
            </div>

            <div className="bg-white p-4 rounded-lg shadow-md">
              <h3 className="text-xl font-semibold">Recommendations</h3>
              <p className="text-2xl">{totalRecommendations}</p>
            </div>
          </section>
        </div>
      </div>
    </PageLayout>
  );
};

export default Dashboard;



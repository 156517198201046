

import React, { useState, useEffect, useRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import PageLayout from '../../../components/PageLayout';
import { useNavigate } from 'react-router-dom';
import { createApiClient } from "../../../modules/services/axios";
import { useTokens } from "../../../modules/services/token";

interface Monitor {
  monitor_id: string;
  friendly_name: string;
}

const CreateStatusPage: React.FC = () => {
  const [monitorId, setMonitorId] = useState<string[]>([]);
  const [monitors, setMonitors] = useState<Monitor[]>([]);
  const [friendlyName, setFriendlyName] = useState('');
  const [customDomain, setCustomDomain] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);

  const { accessToken, idToken } = useTokens(); // Access the tokens from the hook
  const fetchStatusDataCalled = useRef(false);

  const navigate = useNavigate();
  const apiClient = createApiClient();

  useEffect(() => {
    if (!accessToken || !idToken || fetchStatusDataCalled.current) return;

    const loadMonitors = async () => {
      try {
        const response = await apiClient.get(`/api/uptime/monitor`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setMonitors(
          response.data.monitors.map((monitor: any) => ({
            monitor_id: monitor.monitor_id,
            friendly_name: monitor.friendly_name,
          }))
        );
      } catch (error) {
        console.error('Error loading monitors:', error);
        setError('Failed to load monitors.');
      }
    };

    fetchStatusDataCalled.current = true;
    loadMonitors();
  }, [accessToken, idToken]);

  const validateClientName = (name: string) => {
    // Ensure the name is alphanumeric with optional hyphens
    const namePattern = /^[a-zA-Z0-9-]+$/;
    return namePattern.test(name);
  };

  const createStatusPage = async () => {
    setError(null);
    setMessage(null);

      // Validate the client name
      if (!validateClientName(customDomain)) {
        setError('Please enter a valid name without any prefix and suffixes (e.g., clientName ).');
        return;
      }
  
      // Append the fixed domain suffix
      const fullDomain = `${customDomain}.status.cloudbuilders.io`;

    // Construct the monitors parameter based on selected monitor IDs
    const monitors = monitorId.map((id) => ({ id })); // Ensure correct API structure
    const monitor_ids = monitorId; // This is an array of IDs for the monitor_ids field

    if (monitors.length === 0) {
      setError('Please select at least one monitor.');
      return;
    }

    const payload = {
      friendly_name: friendlyName,
      custom_domain: customDomain,
      monitors, // Include the monitors field
      monitor_ids, // Include the monitor_ids field
    };



    try {
      const response = await apiClient.post(`/api/uptime/status`, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });

      if (response.status === 200) {
        setMessage('Status page created successfully!');
      }
    } catch (error: any) {
      console.error('Error creating status page:', error);
  
      // Check for the specific error response
      if (error.response?.data?.detail?.error === 'Status Page Name Already Exists') {
        setError(
          `The Status page name  ${friendlyName} is already in use. Please choose a different name.`
        );
      } else {
        setError('Error creating status page.');
      }
    }
  };

  const onSelect = (selectedList: Monitor[], selectedItem: Monitor) => {
    if (selectedItem.monitor_id) {
      setMonitorId([...monitorId, selectedItem.monitor_id]);
    }
  };

  const onRemove = (selectedList: Monitor[], removedItem: Monitor) => {
    setMonitorId(monitorId.filter((id) => id !== removedItem.monitor_id));
  };

  const viewStatus = () => {
    navigate('/monitorminds/status');
  };

  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-50 flex items-center justify-center bg-gray-200 text-gray-800 p-4">
        <div className="container max-w-lg w-full p-6 bg-gray-200 border border-gray-300 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-6 text-center">Create Status Page</h2>

          <label htmlFor="friendly_name" className="block font-medium mt-4">
            Name of the Status Page
          </label>
          <input
            type="text"
            id="friendly_name"
            placeholder="E.g. Your Brand"
            value={friendlyName}
            onChange={(e) => setFriendlyName(e.target.value)}
            className="w-full p-2 mt-2 rounded border border-gray-300 focus:border-blue-500 focus:ring focus:ring-blue-300"
          />

          <label htmlFor="custom_domain" className="block font-medium mt-4">
            Custom Domain
          </label>
          <input
            type="text"
            id="custom_domain"
            placeholder="E.g. {custom-domain-name}.status.cloudbuilders.io"
            value={customDomain}
            onChange={(e) => setCustomDomain(e.target.value.trim())}
            className={`w-full p-2 mt-2 rounded border ${
              error ? 'border-red-500' : 'border-gray-300'
            } focus:border-blue-500 focus:ring focus:ring-blue-300`}
          />
          {/* {error && customDomain && !validateClientName(customDomain) && (
            <p className="text-red-600 mt-2">
              Please enter a valid name without domain suffixes (e.g., "client").
            </p>
          )} */}

          <label htmlFor="monitors" className="block font-medium mt-4">
            Monitors on Status Page
          </label>
          <Multiselect
            options={monitors}
            selectedValues={monitors.filter((monitor) => monitorId.includes(monitor.monitor_id))}
            onSelect={onSelect}
            onRemove={onRemove}
            displayValue="friendly_name"
            className="w-full mt-2 border border-gray-300 rounded"
          />
          <small className="block text-black-500 font-semibold mb-4">You can add monitors by name or URL.</small>

          <button
            onClick={createStatusPage}
            className="w-full py-2 mt-6 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
          >
            Create Status Page
          </button>

          {error && <p className="mt-4 text-red-600 text-center">{error}</p>}
          {message && (
            <div className="mt-4 text-green-600 text-center">
              <p>{message}</p>
              <button
                onClick={viewStatus}
                className="mt-4 py-2 px-6 bg-green-500 text-white rounded hover:bg-blue-600"
              >
                View Status
              </button>
            </div>
          )}
        </div>
      </div>
    </PageLayout>
  );
};

export default CreateStatusPage;

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperCore } from 'swiper/types';

// Import Swiper styles
import 'swiper/css';

const MySwiper: React.FC = () => {
  return (
    <div className="w-full">
 <p
  className="text-center font-bold mb-4"
  style={{
    fontFamily: "var(--Display-Font-Family-L, Poppins)",
    fontSize: "var(--Display-Font-Size-L, 48px)",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "var(--Display-Line-Height-L, 60px)",
  }}
>
  Trusted by <span className="text-red-500">Innovators</span> Like You
</p>


      <Swiper
        spaceBetween={50}
        slidesPerView={3}
        onSlideChange={() => console.log('Slide changed')}
        onSwiper={(swiper: SwiperCore) => console.log(swiper)}
        className="my-swiper"
      >
        <SwiperSlide>
          <div >
          <img src="/assets/SidsFarm.png" alt='' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div >
          <img src="/assets/symbolname.png" alt='' />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div >
          <img src="/assets/smalljoy.png" alt='' />
          </div>
        </SwiperSlide>
     
     
      </Swiper>
    </div>
  );
};

export default MySwiper;

import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, CircularProgress } from '@mui/material';

interface ActiveResource {
  uuid: string;
  label: string;
  provider: string;
  region: string;
  costs: { category: string; amount: string }[];
  created_at: string;
  [key: string]: any;
}

interface PaginationLinks {
  next?: string;
  prev?: string;
  self?: string;
  last?: string;
}

interface ActiveTableProps {
  activeResources: ActiveResource[];
  loadingActiveResources: boolean;
  errorActiveResources: string | null;
  paginationLinks: PaginationLinks | null;
  handlePageChange: (pageUrl: string | null) => void;
  totalCosts: number | null;
  totalResources: number | null;
  singleResource?: boolean;
  tableHeaders: {
    key: string;
    label: string;
  }[];
}

const ActiveTable: React.FC<ActiveTableProps> = ({
  activeResources,
  loadingActiveResources,
  errorActiveResources,
  paginationLinks,
  handlePageChange,
  totalCosts,
  totalResources,
  singleResource = false,
  tableHeaders,
}) => {
  const getNextPageUrl = () => {
    return paginationLinks?.next || '';
  };

  const getPrevPageUrl = () => {
    return paginationLinks?.prev || '';
  };

  return (
    <div className={singleResource ? "" : "mt-6"}>
      {!singleResource && (
        <>
          <Typography variant="h6" className="font-semibold mb-4">Active Resources</Typography>
          
          <div className="mb-4">
            <Typography variant="body1">Total Costs: ${totalCosts?.toFixed(2) || '0.00'}</Typography>
            <Typography variant="body1">Total Resources: {totalResources || 0}</Typography>
          </div>
        </>
      )}
      {loadingActiveResources ? (
        <CircularProgress />
      ) : errorActiveResources ? (
        <Typography color="error">{errorActiveResources}</Typography>
      ) : (
        <TableContainer className="bg-gray-300 rounded-lg transition-all duration-500">
          <Table>
            <TableHead className="bg-gray-400">
              <TableRow>
                {tableHeaders.map((header) => (
                  <TableCell key={header.key} className="font-semibold !text-lg">
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {activeResources.map((resource) => (
                <TableRow key={resource.uuid} className="transition-all duration-300 hover:bg-gray-400">
                  {tableHeaders.map((header) => (
                    <TableCell key={`${resource.uuid}-${header.key}`}>
                      {header.key === 'costs' ? (
                        resource.costs.map((cost, index) => (
                          <div key={index}>
                            {cost.category}: ${cost.amount}
                          </div>
                        ))
                      ) : header.key === 'created_at' ? (
                        new Date(resource[header.key]).toLocaleDateString()
                      ) : (
                        resource[header.key]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {!singleResource && (
        <div className="mt-4 flex justify-between">
          <Button
            onClick={() => handlePageChange(getPrevPageUrl())}
            disabled={!paginationLinks?.prev}
            variant="contained"
            color="primary"
          >
            Previous
          </Button>
          <Typography variant="body1">
            Page {paginationLinks?.self ? new URL(paginationLinks.self).searchParams.get('page') : 1} of {paginationLinks?.last ? new URL(paginationLinks.last).searchParams.get('page') : 1}
          </Typography>
          <Button
            onClick={() => handlePageChange(getNextPageUrl())}
            disabled={!paginationLinks?.next}
            variant="contained"
            color="primary"
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

export default ActiveTable;




import React, { useState } from 'react';
import PageLayout from '../../components/Layout';
import axios from 'axios';


const SignupPage = () => {

  // State to manage form data
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [user_email, setUserEmail] = useState('');
  const [organization_name, setOrganizationName] = useState('');
  const [subscription_tier, setSubscriptionTier] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState({
    first_name: '',
    last_name: '',
    password: '',
    organization_name: '',
    user_email: '',
    subscription_tier: '',
  });

  const [successMessage, setSuccessMessage] = useState(''); // For success message

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      first_name: '',
      last_name: '',
      password: '',
      organization_name: '',
      user_email: '',
      subscription_tier: '',
    };

    // First Name validation
    if (!first_name.trim()) {
      newErrors.first_name = 'First Name is required.';
      isValid = false;
    }

    // Last Name validation
    if (!last_name.trim()) {
      newErrors.last_name = 'Last Name is required.';
      isValid = false;
    }

    // Password validation
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password.match(passwordRegex)) {
      newErrors.password =
        'Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one special character, and one number.';
      isValid = false;
    }

    // Organization name validation
    const organizationRegex = /^[a-z]+$/;
    if (!organization_name.match(organizationRegex)) {
      newErrors.organization_name = 'Organization name must only contain lowercase letters without spaces.';
      isValid = false;
    }

    // Email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!user_email.match(emailRegex)) {
      newErrors.user_email = 'Please enter a valid email address.';
      isValid = false;
    }

    // Subscription tier validation
    if (subscription_tier !== 'free' && subscription_tier !== 'premium') {
      newErrors.subscription_tier = 'Subscription tier must be either "free" or "premium".';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSuccessMessage(''); // Clear success message
    if (validateForm()) {
      try {
        const api_url = process.env.REACT_APP_API_URL
        const response = await axios.post(`${api_url}/api/auth0/signup`, {
          first_name,
          last_name,
          user_email,
          organization_name,
          subscription_tier,
          password,
        });
        console.log('Signup successful:', response.data);
        setSuccessMessage('Signup successful! You can now log in.'); // Set success message
        setErrors({
          first_name: '',
          last_name: '',
          password: '',
          organization_name: '',
          user_email: '',
          subscription_tier: '',
        });
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorDetail = error.response.data.detail;
          if (errorDetail === 'User already exists') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              user_email: 'User already exists. Please try logging in.',
            }));
          } else if (errorDetail === 'Organization already exists') {
            setErrors((prevErrors) => ({
              ...prevErrors,
              organization_name: 'Organization already exists. Please use a different name.',
            }));
          } else {
            console.error('Signup failed:', error.response.status, errorDetail);
            // Optionally handle other errors
          }
        } else {
          console.error('Error during signup:', error);
        }
      }
    }
  };
  return (
    <PageLayout>
      <div className="min-h-screen bg-gray-100 flex justify-center items-center p-4">
        <div className="bg-white p-4 rounded-lg shadow-lg w-full sm:w-96">
          <h2 className="text-2xl font-bold text-center mb-6">Sign Up</h2>
          {/* Success Message */}
          {successMessage && <p className="text-green-500 text-sm mb-4">{successMessage}</p>}
          <form onSubmit={handleSubmit} className="space-y-3">
            {/* First Name Field */}
            <div>
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                First Name
              </label>
              <input
                type="text"
                id="first_name"
                className="mt-1 p-1.5 w-full border border-gray-300 rounded-md"
                value={first_name}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              {errors.first_name && <p className="text-red-500 text-sm">{errors.first_name}</p>}
            </div>

            {/* Last Name Field */}
            <div>
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                className="mt-1 p-1.5 w-full border border-gray-300 rounded-md"
                value={last_name}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              {errors.last_name && <p className="text-red-500 text-sm">{errors.last_name}</p>}
            </div>

            {/* Email Field */}
            <div>
              <label htmlFor="user_email" className="block text-sm font-medium text-gray-700">
                Email Address
              </label>
              <input
                type="email"
                id="user_email"
                className="mt-1 p-1.5 w-full border border-gray-300 rounded-md"
                value={user_email}
                onChange={(e) => setUserEmail(e.target.value)}
                required
              />
              {errors.user_email && (
                <p className="text-red-500 text-sm">
                  {errors.user_email}{' '}
                  {/* <a href="/login" className="text-blue-500 underline">Log in here</a>. */}
                </p>
              )}
            </div>

            {/* Organization Name Field */}
            <div>
              <label htmlFor="organization_name" className="block text-sm font-medium text-gray-700">
                Organization Name
              </label>
              <input
                type="text"
                id="organization_name"
                className="mt-1 p-1.5 w-full border border-gray-300 rounded-md"
                value={organization_name}
                onChange={(e) => setOrganizationName(e.target.value)}
                required
              />
              {errors.organization_name && <p className="text-red-500 text-sm">{errors.organization_name}</p>}
            </div>

            {/* Subscription Tier Dropdown */}
            <div>
              <label htmlFor="subscription_tier" className="block text-sm font-medium text-gray-700">
                Subscription Tier
              </label>
              <select
                id="subscription_tier"
                className="mt-1 p-1.5 w-full border border-gray-300 rounded-md"
                value={subscription_tier}
                onChange={(e) => setSubscriptionTier(e.target.value)}
                required
              >
                <option value="">Select Tier</option>
                <option value="free">Free</option>
                <option value="premium">Premium</option>
              </select>
              {errors.subscription_tier && <p className="text-red-500 text-sm">{errors.subscription_tier}</p>}
            </div>

            {/* Password Field */}
            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="mt-1 p-1.5 w-full border border-gray-300 rounded-md"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
            </div>

            {/* Submit Button */}
            <div>
              <button
                type="submit"
                className="w-full bg-blue-500 text-white p-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default SignupPage;


import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-red-500 text-white py-6">
      <div className="container mx-auto flex flex-col md:flex-row items-center justify-between">
      <div className="flex flex-col md:flex-row items-center">
          {/* Logo and CB Vista Text with White Background and Black Text */}
          <div className="flex items-center justify-center bg-white text-black py-1 px-1 rounded-md mr-8">
            <img src="/logo.png" alt="CB Vista Logo" className="h-16 w-auto mr-4" />
          
          </div>

          {/* Contact Details */}
          <div className="text-center md:text-left ">
            <p className="text-sm">Email Us At: <a href="mailto:loremipsum@gmail.com" className="underline">loremipsum@gmail.com</a></p>
            <p className="text-sm">Call Us At: <a href="tel:+919000000000" className="underline">+91 90000 00000</a></p>
            <p className="text-sm">Visit Us At: <a href="https://cloudbuilders.io/" target="_blank" rel="noopener noreferrer" className="underline">https://cloudbuilders.io/</a></p>
          </div>
        </div>

        {/* Right Section: Social Icons */}
        <div className="flex space-x-4 mt-4 md:mt-0">
          {/* LinkedIn Icon */}
          <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 48 48" fill="none">
              <g clipPath="url(#clip0_192_1008)">
                <path
                  d="M35.9334 0.0195312H12.0285C5.40523 0.0195312 0 5.42477 0 12.0481V35.991C0 42.5762 5.40523 47.9815 12.0285 47.9815H35.9715C42.5948 47.9815 48 42.5762 48 35.9529V12.0481C47.9619 5.42477 42.5567 0.0195312 35.9334 0.0195312ZM15.9873 38.8459H9.13561V19.052H15.9873V38.8459ZM12.5234 16.0068C10.5821 16.0068 9.02141 14.4462 9.02141 12.5049C9.02141 10.5635 10.5821 9.00288 12.5234 9.00288C14.4647 9.00288 16.0254 10.5635 16.0254 12.5049C15.9873 14.4462 14.4266 16.0068 12.5234 16.0068ZM38.8644 38.8459H38.8263H33.1166V29.2535C33.1166 26.9315 32.8121 23.9624 29.6527 23.9624C26.4171 23.9624 25.8842 26.4747 25.8842 29.1012V38.8459H20.1745V19.052H25.5036V21.7166H25.6558C26.4933 20.194 28.4346 19.014 31.4798 19.014C37.7605 19.014 38.8644 22.6302 38.8644 27.9973V38.8459Z"
                  fill="white"
                  fillOpacity="0.56"
                />
              </g>
              <defs>
                <clipPath id="clip0_192_1008">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>

          {/* Facebook Icon */}
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" viewBox="0 0 48 48" fill="none">
              <g clipPath="url(#clip0_192_1012)">
                <path
                  d="M35.9619 0H12C5.40952 0 0 5.40952 0 12.0381V36C0 42.5905 5.40952 48 12 48H35.9619C42.5905 48 48 42.5905 48 35.9619V12.0381C48 5.40952 42.5905 0 35.9619 0ZM30.4381 24H25.9048V38.8571H20.1905V24H17.1429V17.9048H19.8095V15.3143C19.8095 12.8762 21.0286 9.02857 26.1714 9.02857H30.8571V14.0952H27.5429C27.0095 14.0952 26.2857 14.4381 26.2857 15.619V17.9048H30.9714L30.4381 24Z"
                  fill="white"
                  fillOpacity="0.56"
                />
              </g>
              <defs>
                <clipPath id="clip0_192_1012">
                  <rect width="48" height="48" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from 'react';


const Navbar: React.FC = () => {
  const [hovered, setHovered] = useState(false);
  return (
    <nav style={{backgroundColor:"white",padding:"16px",position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      zIndex: 1000,}} className="flex items-center justify-between ,">

      {/* Logo */}
      <div>
      <img src="/assets/Logocb.png" alt='' />
      </div>

      {/* Buttons */}
      <div className="flex space-x-4">
      <button className="bg-white text-red-500 border border-red-500 px-6 py-2 rounded-lg font-medium hover:bg-gray-800" style={{  backgroundColor: hovered ? "#FFF" : "#FFF",}}>
          SCHEDULE A DEMO
        </button>
     
      </div>
    </nav>
  );
};

export default Navbar;
import React from 'react'


 function Cloud () {
  return (
    <div style={{ backgroundColor: "#f8f9fa", padding: "2rem",marginLeft:"220px",marginRight:"220px",  }}>
    <div style={{ textAlign: "center", marginBottom: "2rem" }}>
      <h1 style={{ fontSize: "64px",fontWeight:"600", color: "#333" }}>
        One Platform, <span style={{ color: "#e74c3c" }}>Endless</span><br/> Possibilities
      </h1>
      <p style={{ color: "#555", maxWidth: "700px", margin: "0 auto",  }}>CB Vista covers every aspect of your cloud operations, leaving no blind spots. Here’s everything you can observe, monitor, and analyse:</p>
      <div></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "32px 48px",
          gap: "48px",
          alignSelf: "stretch",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          marginBottom: "1.5rem",
        }}
      >
        <div>  <img style={{ width: "80px", height: "80px", marginRight: "3rem" }} src="/assets/apm.png" alt='' /></div>
     <div style={{textAlign:"start"}}>
     <h2 style={{ fontSize: "1.5rem", color: "#333", paddingLeft: "1rem" }}>Application Performance Monitoring (APM)</h2>
        <ul style={{ color: "#555" }}>
          <li style={{listStyleType:"disc"}}> Monitor application health in real-time.  </li>
        <li style={{listStyleType:"disc"}}>Identify bottlenecks with detailed latency and error tracking.</li>
        <li style={{listStyleType:"disc"}}>Ensure seamless user experiences with performance analytics.</li></ul>
     </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "32px 48px",
          gap: "48px",
          alignSelf: "stretch",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          marginBottom: "1.5rem",
        }}
      >
        <div>  <img style={{ width: "80px", height: "80px", marginRight: "3rem" }} src="/assets/cloudcost.png" alt='' /></div>
     <div style={{textAlign:"start"}}>
     <h2 style={{ fontSize: "1.5rem", color: "#333", paddingLeft: "1rem" }}>Cloud Cost Optimisation</h2>
        <ul style={{ color: "#555" }}>
          <li style={{listStyleType:"disc"}}>Gain clear visibility into cloud spending trends.  </li>
        <li style={{listStyleType:"disc"} }>Track budgets and identify areas for cost optimization.</li>
        <li style={{listStyleType:"disc"}}>Get actionable recommendations to maximise ROI.</li></ul>
     </div>

      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "32px 48px",
          gap: "48px",
          alignSelf: "stretch",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          marginBottom: "1.5rem",
        }}
      >
        <div>  <img style={{ width: "80px", height: "80px", marginRight: "3rem" }} src="/assets/Alert.png" alt='' /></div>
     <div style={{textAlign:"start"}}>
     <h2 style={{ fontSize: "1.5rem", color: "#333", paddingLeft: "1rem" }}>Dashboards & Alerting</h2>
        <ul style={{ color: "#555" }}>
          <li style={{listStyleType:"disc"}}>Unified dashboards for all your cloud data.  </li>
        <li style={{listStyleType:"disc"}}>Customisable views to suit DevOps and FinOps needs.</li>
        <li style={{listStyleType:"disc"}}>Proactive alerts to detect issues before they escalate.</li></ul>
     </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "32px 48px",
          gap: "48px",
          alignSelf: "stretch",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          marginBottom: "1.5rem",
        }}
      >
        <div>  <img style={{ width: "80px", height: "80px", marginRight: "3rem" }} src="/assets/monitor.png" alt='' /></div>
     <div style={{textAlign:"start"}}>
     <h2 style={{ fontSize: "1.5rem", color: "#333", paddingLeft: "1rem" }}>Cloud Infrastructure Monitoring</h2>
        <ul style={{ color: "#555" }}>
          <li style={{listStyleType:"disc"}}>Track resource utilization to avoid saturation. </li>
        <li  style={{listStyleType:"disc"}}>Visualise traffic patterns and anticipate demand spikes.</li>
        <li style={{listStyleType:"disc"}}> Optimise cloud costs with integrated FinOps tools.
        </li></ul>
     </div>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          padding: "32px 48px",
          gap: "48px",
          alignSelf: "stretch",
          backgroundColor: "#fff",
          borderRadius: "16px",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          marginBottom: "1.5rem",
        }}
      >
        <div>  <img style={{ width: "80px", height: "80px", marginRight: "3rem" }} src="/assets/uptime.png" alt='' /></div>
     <div style={{textAlign:"start"}}>
     <h2 style={{ fontSize: "1.5rem", color: "#333", paddingLeft: "1rem" }}>Reliable Uptime Monitoring</h2>
        <ul style={{ color: "#555" }}>
          <li style={{listStyleType:"disc"}}>Monitor uptime across all services and regions. </li>
        <li style={{listStyleType:"disc"}}>Detect outages instantly with real-time notifications.</li>
        <li style={{listStyleType:"disc"}}> Ensure 24/7 availability for mission-critical systems.</li></ul>
     </div>
      </div>
    </div>
    </div>
  )
}
export default Cloud




import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Box,
  Container,
  Grid,
  Paper,
  Typography,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";
import PageLayout from "../../../components/PageLayout";
import debounce from "lodash.debounce";
import CostTable from "../../../ui/table";
import BarGraph from "../../../components/graph/bargraph";
import { createApiClient } from "../../../modules/services/axios";
import { useTokens } from "../../../modules/services/token";

interface CostData {
  amount: string;
  average_daily_spend: number;
  currency: string;
  month: string;
}

interface ForecastedCost {
  amount: number;
  currency: string;
}

interface GroupedCost {
  accrued_at: string;
  total_amount: number;
  services: ServiceCost[];
}


interface ServiceCost {
  service: string;
  total_cost: number;
}

interface ChartData {
  accrued_at: string[];
  total_amount: number[];
}





const CostList: React.FC = () => {
  const [currentMonthCost, setCurrentMonthCost] = useState<CostData | null>(null);
  const [forecastedCost, setForecastedCost] = useState<ForecastedCost | null>(null);
  const [groupedCosts, setGroupedCosts] = useState<GroupedCost[]>([]);
  const [chartData, setChartData] = useState<ChartData>({
    accrued_at: [],
    total_amount: [],
  });
  const [totalAmount, setTotalAmount] = useState<number | null>(null);
  const [serviceCosts, setServiceCosts] = useState<Record<string, number>>({});
  const [previousMonthTotalCost, setPreviousMonthTotalCost] = useState<CostData | null>(null);
  const [, setPreviousMonthForecastedCost] = useState<ForecastedCost | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { accessToken, idToken } = useTokens(); // Fetch tokens
  const hasFetchedData = useRef(false); // Prevent multiple API calls

  // Pagination state
  const [page, setPage] = useState(0);
  const rowsPerPage = 10; // Show 10 items per page

  // Sorting state
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

  // Date range state
  const [startDate, setStartDate] = useState<string>(() => {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 2, 1);
    return firstDayOfMonth.toISOString().split('T')[0];
  });
  const [endDate, setEndDate] = useState<string>(() => {
    const currentDate = new Date();
    const twoDaysAgo = new Date(currentDate.setDate(currentDate.getDate()- 0));
    return twoDaysAgo.toISOString().split('T')[0];
  });

  const apiClient = createApiClient(); 
  // Function to fetch data
  const fetchCosts = async () => {
    try {

      const today = new Date();
      const twoDaysAgo = new Date(today.setDate(today.getDate() - 2));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const dynamicEndDate = twoDaysAgo.toISOString().split('T')[0];

    // Fetch current month data (December)
    const currentMonthResponse = await apiClient.get(
      `/api/vantage/costs`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
        params: {
          cost_report_token: 'rprt_2872f1d4ba6fe155',
          start_date: startDate,
          end_date: endDate,
          // groupings: 'costs_by_date',
        }
      }
    );
    const currentMonthData = currentMonthResponse.data;

    const accruedAtArray = currentMonthData?.['costs_by_date'].map((item: any) => item.accrued_at);
    const TotalAmount = currentMonthData?.['costs_by_date'].map((item: any) => item.total_amount);

    setChartData({
      accrued_at: accruedAtArray,
      total_amount: TotalAmount
    })

      // Fetch previous month's data (November)
      const previousMonthStart = new Date(today.getFullYear(), -1, 1);
      const previousMonthEnd = new Date(today.getFullYear(), 0);
      const previousMonthStartdate = previousMonthStart.toISOString().split("T")[0];
      const previousMonthEnddate = previousMonthEnd.toISOString().split("T")[0];

      const previousMonthResponse = await apiClient.get(
        `/api/vantage/costs`,  {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
          
          params: {
            cost_report_token: 'rprt_2872f1d4ba6fe155',
            start_date: previousMonthStartdate,
            end_date: previousMonthEnddate,
            groupings: 'service',
          }
        }
      );
      const previousMonthData = previousMonthResponse.data;

      // Set previous month data (November)
      setPreviousMonthTotalCost({
        amount: previousMonthData.total_cost.amount,
        average_daily_spend: previousMonthData.total_cost.average_daily_spend,
        currency: previousMonthData.total_cost.currency,
        month: previousMonthStart.toLocaleString('default', { month: 'long' }),
      });
      setPreviousMonthForecastedCost(previousMonthData.forecasted_cost);
      // console.log('previousMonthData ======================', previousMonthData)

      // Set current month data (December)
      setCurrentMonthCost({
        amount: currentMonthData.total_cost.amount,
        average_daily_spend: currentMonthData.total_cost.average_daily_spend,
        currency: currentMonthData.total_cost.currency,
        month: new Date(startDate).toLocaleString('default', { month: 'long' }),
      });
      setForecastedCost(currentMonthData.forecasted_cost);

      if (Array.isArray(currentMonthData.grouped_costs)) {
        // console.log('currentMonthData.grouped_costs ======================', currentMonthData.grouped_costs)
        setGroupedCosts(currentMonthData.grouped_costs);
      } else {
        setGroupedCosts([]);
      }

      if (currentMonthData.total_amount) {
        setTotalAmount(parseFloat(currentMonthData.total_amount));
      }

      const currentMonthServiceCosts = calculateMonthlyServiceCosts(currentMonthData.grouped_costs);
      // console.log('currentMonthServiceCosts ======================', currentMonthServiceCosts)
      setServiceCosts(currentMonthServiceCosts);

    } catch (error) {
      console.error("Error fetching cost data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const calculateMonthlyServiceCosts = (groupedCosts: GroupedCost[]) => {
    // console.log('groupedCosts ============', groupedCosts)
    return groupedCosts.reduce((acc: Record<string, number>, cost) => {
      cost.services.forEach((service) => {
        if (!acc[service.service]) {
          acc[service.service] = 0;
        }
        acc[service.service] += service.total_cost;
      });
      return acc;
    }, {} as Record<string, number>);
  };

  // Debounced version of fetchCosts
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchCostsDebounced = useCallback(
    debounce(() => {
      fetchCosts();
    }, 500),
    []
  );

  useEffect(() => {
    fetchCostsDebounced();
    // Cleanup on unmount
    return () => fetchCostsDebounced.cancel();
  }, [startDate, endDate, fetchCostsDebounced]);

  

  


  // Pagination handlers
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  // Sorting handler
  const handleSort = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };


  return (
    <PageLayout>
      
      <Container maxWidth="lg">
        <Box className="p-6 bg-gray-100" style={{ height: "100vh", overflow: "auto" }}>
          <Typography variant="h4" gutterBottom>
            Understand Spend Trends
          </Typography>

          <Grid container spacing={4} alignItems="stretch">
            {previousMonthTotalCost && (
              <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
                  <Typography variant="h6">{previousMonthTotalCost.month} Total Cost</Typography>
                  <Typography variant="body1">
                    ${parseFloat(previousMonthTotalCost.amount).toFixed(2)} {previousMonthTotalCost.currency}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {previousMonthTotalCost.average_daily_spend.toFixed(2)} Avg. Daily Spend
                  </Typography>
                </Paper>
              </Grid>
            )}
            {currentMonthCost && (
              <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
                <Typography variant="h6">
                    {new Date(startDate).toLocaleString('default', { month: 'long' })} {new Date(startDate).getDate()} -{' '}
                    {new Date(endDate).toLocaleString('default', { month: 'long' })} {new Date(endDate).getDate()}
                  </Typography>
                  <Typography variant="body1">
                    ${parseFloat(currentMonthCost.amount).toFixed(2)} {currentMonthCost.currency}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {currentMonthCost.average_daily_spend.toFixed(2)} Avg. Daily Spend
                  </Typography>
                </Paper>
              </Grid>
            )}
            {forecastedCost && (
              <Grid item xs={12} sm={4}>
                <Paper elevation={3} style={{ padding: "16px", height: "100%" }}>
                  <Typography variant="h6">Forecasted Cost</Typography>
                  <Typography variant="body1">
                    ${forecastedCost.amount.toFixed(2)} ({forecastedCost.currency})
                  </Typography>
                </Paper>
              </Grid>
            )}
          </Grid>

          {/* Date Range Picker */}
          <Grid container spacing={2} alignItems="center" style={{ marginTop: "40px", marginBottom: "20px", justifyContent: "flex-end" }}>
            <Grid item>
              <TextField
                label="Start Date"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <TextField
                label="End Date"
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => {
                  setIsLoading(true); // Set loading to true before triggering fetch
                  fetchCosts();
                }}
                disabled={isLoading} // Disable the button when loading
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Hit Query"
                )}
              </Button>
            </Grid>
          </Grid>

          <Box mt={4}>
            <BarGraph chartData={chartData} totalAmount={totalAmount} />
          </Box>

          <Typography variant="h4" gutterBottom style={{ marginTop: "40px" }}>
            Services
          </Typography>
          <CostTable
  serviceCosts={groupedCosts as any}
  page={page}
  rowsPerPage={rowsPerPage}
  
  handleChangePage={handleChangePage}
/>
        </Box>
      </Container>
    </PageLayout>
  );
};

export default CostList;






import React from 'react'


 function Tools() {
  return (
    <div style={{position:"relative",}}>
      <img width={'100%'} src='/assets/Tool 13.png' alt=''/>
      <div style={{position:"absolute", textAlign:"center",top:"50%",left:"50%",transform:"translate(-50%,-50%)",}}>
      <h1 className="text-4xl font-bold md:text-5xl lg:text-6xl text-gray-900" style={{lineHeight:"76px"}}>
          Too Many Tools.<br />
          Too Much Data.<br />
          No Clear Picture.
         </h1> 
         <p className="mt-4 text-lg md:text-xl text-gray-600">
          When teams work in silos, the result can be confusion, missed insights,
          and wasted time.
        </p>
        </div>
    </div>
  )
}
export default Tools;
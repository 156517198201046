



import axios from 'axios';

// Function to create an Axios instance without token
const createApiClient = () => {
  const isLocal = window.location.hostname === 'localhost';
  const api_url = isLocal 
    ? process.env.REACT_APP_API_URL 
    : window.location.origin;
  if (!api_url) {
    throw new Error('API URL is not defined in the environment variables');
  }

  // Create an Axios instance with common headers
  const instance = axios.create({
    baseURL: api_url,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  // Return the methods for making API requests
  return {
    get: (url: string, config = {}) => instance.get(url, { ...config }),
    post: (url: string, data = {}, config = {}) => instance.post(url, data, config),
    put: (url: string, data = {}, config = {}) => instance.put(url, data, config),
    delete: (url: string, config = {}) => instance.delete(url, config),
  };
};

// Export the function that creates the API client
export { createApiClient };

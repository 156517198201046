import React, { useState } from "react";

import PageLayout from "../../../components/PageLayout";


const CreateQuota: React.FC = () => {
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const filterOptions: Record<string, string[]> = {
    "Suggested filters": ["Assigned to me", "Instance", "Volume", "Active", "With violated constraints"],
    "Data source": ["AWS HQ", "AWS Marketing"],
    "Resource type": ["Api Request", "BigQuery", "Bucket", "Business Analyst"],
    " Resource State": ["Billing Only", "Active"],
    "pool": ["AwsHQ", "AwsMarketing", "Ali dev"],
    "Owner": ["Julian Jackson", "Christian Thomas"],
    "K8s node": ["orchid-staging"],
  };

  const handleFilterClick = (filter: string) => {
    setActiveFilter(filter === activeFilter ? null : filter);
  };

  const handleOptionSelect = (option: string) => {
    setSelectedOption(option);
    setActiveFilter(null);
  };

  return (
    <PageLayout>
 <div className="bg-gray-100 min-h-screen py-8">
      <div className="max-w-md mx-auto p-4 sm:p-6 md:p-8 bg-gray-200 rounded-lg shadow-md">
          <div className="flex items-center gap-2 text-sm mb-4">
           
          </div>
          <h1 className="text-2xl font-normal text-gray-900 mb-8">
            Create Quota or Budget Policy
          </h1>
          <form>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="name">
                Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="name"
                className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                placeholder="Enter policy name"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor="type">
                Type <span className="text-red-500">*</span>
              </label>
              <select
                id="type"
                className="w-full border border-gray-300 rounded-md p-2 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                <option value="" disabled>
                  Select type
                </option>
                <option value="Resource Quota">Resource Quota</option>
                <option value="Recurring Budget">Recurring Budget</option>
                <option value="Expiring Budget">Expiring Budget</option>
              </select>
            </div>
            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-700 mb-2">Filters:</label>
              <div className="grid grid-cols-3 gap-1">
                {Object.entries(filterOptions).map(([filter, options]) => (
                  <div key={filter} className="relative">
                    <button
                      type="button"
                      className="w-full px-4 py-2 bg-gray-50 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-100 text-left flex justify-between items-center"
                      onClick={() => handleFilterClick(filter)}
                    >
                      <span>{filter}</span>
                      <svg className="w-5 h-5 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                      </svg>
                    </button>
                    {activeFilter === filter && (
                      <div className="absolute left-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                        {options.map((option) => (
                          <button
                            key={option}
                            type="button"
                            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                            onClick={() => handleOptionSelect(option)}
                          >
                            {option}
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <p className="text-sm text-gray-700">
                Selected Filter Option:{" "}
                <span className="font-medium text-gray-800">{selectedOption || "None"}</span>
              </p>
            </div>
            <div className="flex justify-start gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
              >
                Save
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-white text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </PageLayout>
  );
};

export default CreateQuota;


import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export const useTokens = () => {
  const { isAuthenticated, getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [idToken, setIdToken] = useState<string | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated) {
        try {
          // Get ID token
          const idTokenClaims = await getIdTokenClaims();
          if (idTokenClaims) {
            setIdToken(idTokenClaims.__raw); // __raw contains the raw JWT
          }

          // Get Access token
          const accessToken = await getAccessTokenSilently();
          setAccessToken(accessToken);
        } catch (error) {
          console.error("Error fetching tokens:", error);
        }
      }
    };

    fetchTokens();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  return { idToken, accessToken };
};


import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PageLayout from '../../../components/PageLayout';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Typography, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import ActiveTable from '../../../ui/ActiveTable';
import { createApiClient } from "../../../modules/services/axios";
import { useTokens } from "../../../modules/services/token";


interface ResourceReport {
  created_at: string;
  created_by_token: string | null;
  filter: string | null;
  title: string;
  token: string;
  user_token: string | null;
  workspace_token: string;
}

interface ResourceCost {
  amount: string;
  category: string;
}

interface ActiveResource {
  account_id: string;
  billing_account_id: string;
  costs: ResourceCost[];
  created_at: string;
  label: string;
  metadata: {
    version: number;
  };
  provider: string;
  region: string;
  token: string;
  type: string;
  uuid: string;
}

interface SingleResource extends ActiveResource { }

interface PaginationLinks {
  next?: string;
  prev?: string;
  self?: string;
  last?: string;
}

const TABLE_HEADERS = [
  { key: 'label', label: 'Label' },
  { key: 'provider', label: 'Provider' },
  { key: 'region', label: 'Region' },
  { key: 'costs', label: 'Cost' },
  { key: 'created_at', label: 'Created At' },
];


const ActiveResourceReports: React.FC = () => {
  const [resourceReports, setResourceReports] = useState<ResourceReport[]>([]);
  const [activeResources, setActiveResources] = useState<ActiveResource[]>([]);
  const [selectedResource, setSelectedResource] = useState<SingleResource | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [loadingActiveResources, setLoadingActiveResources] = useState<boolean>(false);
  const [errorActiveResources, setErrorActiveResources] = useState<string | null>(null);
  const [paginationLinks, setPaginationLinks] = useState<PaginationLinks | null>(null);
  const [totalCosts, setTotalCosts] = useState<number | null>(null);
  const [totalResources, setTotalResources] = useState<number | null>(null);
  const { accessToken, idToken } = useTokens(); // Fetch tokens
  const hasFetchedData = useRef(false); // Prevent multiple API calls

  const apiClient = createApiClient();

  useEffect(() => {
    const fetchResourceReports = async () => {
      if (hasFetchedData.current || !accessToken || !idToken) return; // Ensure tokens are available and avoid duplicate calls
      hasFetchedData.current = true;

      try {
        const response = await apiClient.get(`/api/vantage/resource_reports`,{
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setResourceReports(response.data.resource_reports || []);
      } catch (error) {
        setError(error instanceof Error ? error.message : 'Unknown error');
      } finally {
        setLoading(false);
      }
    };

    fetchResourceReports();
  },  [accessToken, idToken]);

  const fetchActiveResources = async (url: string) => {
    setLoadingActiveResources(true);
    setErrorActiveResources(null);
    try {
      const apiClient = createApiClient();

      const updatedUrl = url.replace('https://api.vantage.sh/v2', `/api/vantage`);
      const response = await apiClient.get(updatedUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });
      setActiveResources(response.data.resources || []);
      setPaginationLinks(response.data.links || {});
      setTotalCosts((prevTotal) => (prevTotal || 0) + (response.data.total_costs || 0));
      setTotalResources((prevTotal) => (prevTotal || 0) + (response.data.total_resources || 0));
    } catch (error) {
      setErrorActiveResources(error instanceof Error ? error.message : 'Unknown error');
    } finally {
      setLoadingActiveResources(false);
    }
  };

  const fetchTotals = async (reportToken: string) => {
    try {
      const response = await apiClient.get(`/api/vantage/resource_total?resource_report_token=${reportToken}`,{
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });
      setTotalCosts(response.data.total_costs || 0);
      setTotalResources(response.data.total_resources || 0);
    } catch (error) {
      console.error('Error fetching total costs and resources:', error);
    }
  };

  const fetchSingleResource = async (token: string) => {
    try {
      const response = await apiClient.get(`/api/vantage/resources/${token}?include_cost=true`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });
      if (!response.data) {
        throw new Error('Failed to fetch resource details');
      }
      const data = await response.data();
      setSelectedResource(data);
    } catch (error) {
      console.error('Error fetching single resource:', error);
    }
  };

  useEffect(() => {
    if (resourceReports.length > 0 && accessToken && idToken) {
      const reportToken = resourceReports[0].token;
      const initialUrl = `/api/vantage/resources?resource_report_token=${reportToken}&include_cost=true&page=1&limit=25`;
      fetchActiveResources(initialUrl);
      fetchTotals(reportToken);
    }
  },  [resourceReports, accessToken, idToken]);

  const handlePageChange = useCallback((pageUrl: string | null) => {
    if (pageUrl) {
      fetchActiveResources(pageUrl);
    }
  }, []);

  const totalCostsMemoized = useMemo(() => {
    return activeResources.reduce((sum, resource) => {
      return sum + resource.costs.reduce((costSum, cost) => costSum + parseFloat(cost.amount), 0);
    }, 0);
  }, [activeResources]);

  return (
    <PageLayout>
      <div className="bg-gray-100 flex flex-col min-h-screen ">
        <div className="flex-1">
          <div className="p-4 bg-gray-200 rounded-lg shadow-md max-w-6xl mx-auto mt-6 transition-all duration-500">
            <Typography variant="h4" className="text-3xl font-semibold mb-4">Active Resource Reports</Typography>

            {loading ? (
              <CircularProgress />
            ) : error ? (
              <div className="text-center p-4 text-red-800 rounded-md">
            <p>We’re experiencing technical difficulties. Please try again in a few moments.</p>
            </div>
            ) : (
              <TableContainer className="bg-gray-300 rounded-lg transition-all duration-500  ">
                <Table>
                  <TableHead className="bg-gray-400">
                    <TableRow>
                      <TableCell className="font-semibold !text-lg">Title</TableCell>
                      <TableCell className="font-semibold !text-lg">Created At</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {resourceReports.map((report) => (
                      <TableRow key={report.token} className="cursor-pointer hover:bg-gray-200">
                        <TableCell>{report.title}</TableCell>
                        <TableCell>{new Date(report.created_at).toLocaleDateString()}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            {activeResources.length > 0 && (
              <ActiveTable
                activeResources={activeResources}
                loadingActiveResources={loadingActiveResources}
                errorActiveResources={errorActiveResources}
                paginationLinks={paginationLinks}
                handlePageChange={handlePageChange}
                totalCosts={totalCosts}
                totalResources={totalResources}
                tableHeaders={TABLE_HEADERS}
              />
            )}
          </div>
        </div>

        <Dialog open={Boolean(selectedResource)} onClose={() => setSelectedResource(null)}>
          <DialogTitle>Resource Details</DialogTitle>
          { <DialogContent>
            {selectedResource && (
              <ActiveTable
                activeResources={[selectedResource]}
                loadingActiveResources={false}
                errorActiveResources={null}
                paginationLinks={null}
                handlePageChange={() => {}}
                totalCosts={null}
                totalResources={null}
                singleResource={true}
                tableHeaders={TABLE_HEADERS}
              />
            )}
          </DialogContent> }
          <DialogActions>
            <Button onClick={() => setSelectedResource(null)} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </PageLayout>
  );
};

export default ActiveResourceReports;




import React, { useState } from "react";
import { Box, TextField, Button, Typography, Card, CardContent } from "@mui/material";

const Analysis: React.FC = () => {
  const [message, setMessage] = useState("");
  const [analysis, setAnalysis] = useState<string | null>(null);

  const handleAnalyze = () => {
    if (message.toLowerCase().includes("30 days")) {
      setAnalysis("A 30-day delay may cause minor disruptions to the timeline, leading to slight cost overruns and potential client dissatisfaction.");
    } else if (message.toLowerCase().includes("60 days")) {
      setAnalysis("This is Affecting Project developing and planning deli.");
    } else {
      setAnalysis("This delay is affecting project milestones and dependencies on the Product Owner (PO).");
    }
  };

  return (
    <Box className="p-6 bg-gray-100 min-h-screen flex flex-col items-center">
      <Card className="w-full max-w-7xl shadow-lg">
        <CardContent>
          <Typography variant="h4" className="mb-6 text-center">
            What if Analysis
          </Typography>

          <TextField
            label="Enter details (e.g., 'Task is delayed by 30 days')"
            variant="outlined"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="mb-6"
          />

          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleAnalyze}
            className="bg-blue-500 hover:bg-blue-600 text-lg py-2"
          >
            Analyze
          </Button>

          {analysis && (
            <Box className="mt-6 p-6 bg-gray-50 border border-gray-200 rounded">
              <Typography variant="h5" className="mb-4">
                Analysis Result:
              </Typography>
              <Typography variant="body1" className="leading-relaxed">
                {analysis}
              </Typography>
            </Box>
          )}
        </CardContent>
      </Card>
    </Box>
  );
};

export default Analysis;


import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";

interface ApiResponse {
  time: string;
  rolling_mean: string;
  rolling_std: string;
  upper_bound: string;
  lower_bound: string;
  residual: string;
  anomaly: string;
  value: string;
}

interface AnomalyGraphProps {
  graphData: ApiResponse[];
}

const Graph: React.FC<AnomalyGraphProps> = ({ graphData }) => {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
   
    if (!chartRef.current) return;

    const chartInstance = echarts.init(chartRef.current);

    const getChartValue = (item: ApiResponse) =>
      item.value ? parseFloat(item.value) : 0;

    const chartData = {
      xAxis: graphData.map((item) => item.time).reverse(),
      value: graphData.map((item) => getChartValue(item)),
      upperBound: graphData.map((item) => parseFloat(item.upper_bound)),
      lowerBound: graphData.map((item) => parseFloat(item.lower_bound)),
      anomaly: graphData.map((item) =>
        item.anomaly.toLowerCase() === "true" ? "True" : "False"
      ),
    };

    const options = {
      title: { text: "Anomaly Detection" },
      tooltip: {
        trigger: "axis",
        formatter: (params: any) => {
          
          const anomalyStatus = params[3]?.data ? "True" : "False";
          return `
            <div style="font-size: 14px; color: #333;">
              <div>${params[0]?.axisValue}</div>
              <div>
                <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: blue; margin-right: 4px;"></span>
                Value: ${params[0]?.data}
              </div>
              <div>
                <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: green; margin-right: 4px;"></span>
                Upper Bound: ${params[1]?.data}
              </div>
              <div>
                <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: orange; margin-right: 4px;"></span>
                Lower Bound: ${params[2]?.data}
              </div>
              <div>
                <span style="display: inline-block; width: 8px; height: 8px; border-radius: 50%; background: red; margin-right: 4px;"></span>
                Anomaly: ${anomalyStatus}
              </div>
            </div>`;
        },
      },
      legend: { data: ["Value", "Upper Bound", "Lower Bound", "Anomaly"] },
      xAxis: {
        type: "category",
        data: chartData.xAxis,
        axisLine: { lineStyle: { color: "#333", width: 2 } },
      },
      yAxis: {
        type: "value",
        axisLine: { lineStyle: { color: "#333", width: 2 } },
      },
      series: [
        {
          name: "Value",
          type: "line",
          data: chartData.value,
          smooth: true,
        },
        {
          name: "Upper Bound",
          type: "line",
          data: chartData.upperBound,
          smooth: true,
        },
        {
          name: "Lower Bound",
          type: "line",
          data: chartData.lowerBound,
          smooth: true,
        },
        {
          name: "Anomaly",
          type: "scatter",
          data: chartData.anomaly.map((anomaly, index) =>
            anomaly === "True"
              ? { value: chartData.value[index], itemStyle: { color: "#ff0000" } }
              : null
          ),
          symbolSize: 10,
        },
      ],
    };

    chartInstance.setOption(options);

    // Clean up the chart on component unmount
    return () => {
      chartInstance.dispose();
    };
  }, [graphData]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: "500px" }}
    ></div>
  );
};

export default Graph;

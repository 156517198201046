

import React, { useEffect, useRef, useState } from 'react';
import PageLayout from '../../../components/PageLayout';
import { Link } from 'react-router-dom';
import { createApiClient } from "../../../modules/services/axios";
import { useTokens } from "../../../modules/services/token";
import Multiselect from 'multiselect-react-dropdown';

type StatusPage = {
  custom_domain: string;
  friendly_name: string;
  page_id: number;
  monitors: number[];
  sort: number;
  standard_url: string;
  status: number;
};

type Monitor = {
  monitor_id: string;
  friendly_name: string;
  url: string;
};

const Status: React.FC = () => {
  const [statusPages, setStatusPages] = useState<StatusPage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentStatusPage, setCurrentStatusPage] = useState<StatusPage | null>(null);
  const [friendlyName, setFriendlyName] = useState('');
  const [customUrl, setCustomUrl] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [monitorsList, setMonitorsList] = useState<Monitor[]>([]);
  const [selectedMonitors, setSelectedMonitors] = useState<Monitor[]>([]);

  const { accessToken, idToken } = useTokens();

  const fetchStatusDataCalled = useRef(false);
  useEffect(() => {
    const apiClient = createApiClient();
    if (!accessToken || !idToken || fetchStatusDataCalled.current) return;

    const fetchStatusData = async () => {
      try {
        const response = await apiClient.get(`/api/uptime/status`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setStatusPages(response.data.psps || []);
      } catch (err) {
        setError('Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    const fetchMonitors = async () => {
      try {
        const response = await apiClient.get(`/api/uptime/monitor`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        });
        setMonitorsList(response.data.monitors || []);
      } catch (error) {
        console.error('Failed to fetch monitors list:', error);
      }
    };

    fetchStatusData();
    fetchMonitors();
    fetchStatusDataCalled.current = true;
  }, [accessToken, idToken]);

  const handleEdit = (statusPage: StatusPage) => {
    setCurrentStatusPage(statusPage);
    setFriendlyName(statusPage.friendly_name);
    setCustomUrl(statusPage.custom_domain);
    setUpdateSuccess(false);
    setSelectedMonitors(
      statusPage.monitors.map(monitor_id => {
        const monitor = monitorsList.find(m => m.monitor_id === monitor_id.toString());
        return {
          monitor_id: monitor_id.toString(),
          friendly_name: monitor ? monitor.friendly_name : `Monitor ${monitor_id}`,
          url: monitor ? monitor.url : ''
        };
      })
    );
    setIsModalOpen(true);
  };

  const handleUpdate = async () => {
    if (!currentStatusPage) return;
    setIsUpdating(true);
    setUpdateSuccess(false);
    try {
      const apiClient = createApiClient();
      const updatedMonitorIds = selectedMonitors.map(monitor => monitor.monitor_id);
      const response = await apiClient.put(
        `/api/uptime/status/${currentStatusPage.page_id}`,
        {
          friendly_name: friendlyName,
          custom_domain: customUrl,
          monitor_ids: updatedMonitorIds,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            id_Token: idToken,
          },
        }
      );

      if (response.status === 200) {
        setStatusPages(
          statusPages.map((status) =>
            status.page_id === currentStatusPage.page_id
              ? {
                ...status,
                friendly_name: friendlyName,
                custom_domain: customUrl,
                monitors: updatedMonitorIds.map(id => parseInt(id, 10)),
              }
              : status
          )
        );
        setUpdateSuccess(true);
        setTimeout(() => setIsModalOpen(false), 2000);
      } else {
        alert('Failed to update status page: ' + (response.data.message || 'Unknown error'));
      }
    } catch (error) {
      console.error('Error updating status page:', error);
      alert('Error updating status page');
    } finally {
      setIsUpdating(false);
    }
  };

  const handleDelete = async (id: number) => {
    const isConfirmed = window.confirm('Are you sure you want to delete this status page?');
    if (!isConfirmed) return;

    try {
      const apiClient = createApiClient();
      const response = await apiClient.delete(`/api/uptime/status/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          id_Token: idToken,
        },
      });

      if (response.status === 200) {
        setStatusPages(statusPages.filter((status) => status.page_id !== id));
        alert('Status page deleted successfully');
      } else {
        alert('Failed to delete status page');
      }
    } catch (error) {
      console.error('Error deleting status page:', error);
      alert('Error deleting status page');
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setFriendlyName('');
    setCustomUrl('');
    setSelectedMonitors([]);
  };

  if (error) return <div>{error}</div>;

  return (
    <PageLayout>
      <div className="flex flex-col md:flex-row h-screen bg-gray-100">
        <div className="p-4 bg-gray rounded-lg shadow-md w-full md:max-w-5xl mx-auto mt-6">
          <div className="flex justify-between items-center mb-4">
            <h1 className="text-3xl font-bold">Status Pages</h1>
            <Link to="/monitorminds/statuspage">
              <button className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">
                + Create Status Page
              </button>
            </Link>
          </div>
          <table className="w-full table-auto border-collapse border border-gray-300 text-sm">
            <thead>
              <tr className="bg-gray-400">
                <th className="px-4 py-2 text-left !text-lg border-b">Name</th>
                <th className="px-4 py-2 text-left !text-lg border-b">Monitors</th>
                <th className="px-4 py-2 text-left !text-lg border-b">Hosted URL</th>
                <th className="px-4 py-2 text-left  !text-lg border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {statusPages.map((status) => (
                <tr key={status.page_id} className="hover:bg-gray-100 border-2 border-gray-300">
                  <td className="px-4 py-2 border-b">{status.friendly_name}</td>
                  <td className="px-4 py-2 border-b">{status.monitors.length}</td>
                  <td className="px-4 py-2 border-b">
                    {status.custom_domain ? (
                      <a href={`https://${status.custom_domain}`} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        View
                      </a>
                    ) : (
                      <a href={status.standard_url} className="text-blue-500" target="_blank" rel="noopener noreferrer">
                        View Standard URL
                      </a>
                    )}
                  </td>
                  <td className="px-4 py-2 border-b">
                    <button
                      onClick={() => handleEdit(status)}
                      className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-yellow-600 mr-2"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(status.page_id)}
                      className="px-2 py-1 bg-gray-500 text-white rounded-lg hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-11/12 md:w-1/3">
              <h2 className="text-xl font-semibold mb-4">Edit Status Page</h2>
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Name</label>
                <input
                  type="text"
                  value={friendlyName}
                  onChange={(e) => setFriendlyName(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                  disabled={isUpdating}
                />
              </div>
              {/* <div className="mb-4">
                <label className="block text-gray-700 mb-2">Custom Domain</label>
                <input
                  type="text"
                  value={customUrl}
                  onChange={(e) => setCustomUrl(e.target.value)}
                  className="w-full px-4 py-2 border rounded-lg"
                  disabled={isUpdating}
                />
              </div> */}
              <div className="mb-4">
                <label className="block text-gray-700 mb-2">Monitors</label>
                <Multiselect
                  options={monitorsList}
                  selectedValues={selectedMonitors}
                  onSelect={(selectedList: Monitor[]) => setSelectedMonitors(selectedList)}
                  onRemove={(selectedList: Monitor[]) => setSelectedMonitors(selectedList)}
                  displayValue="friendly_name"
                  placeholder="Select Monitors"
                  disable={isUpdating}
                  style={{
                    chips: {
                      background: '#3B82F6'
                    },
                    multiselectContainer: {
                      color: '#374151'
                    },
                    searchBox: {
                      border: '1px solid #D1D5DB',
                      borderRadius: '0.375rem',
                      padding: '0.5rem'
                    }
                  }}
                />
              </div>

              {updateSuccess && (
                <p className="text-green-600 text-center mb-4">
                  Status page updated successfully!
                </p>
              )}
              <div className="flex justify-end">
                <button
                  onClick={handleCancel}
                  className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400 mr-2"
                  disabled={isUpdating}
                >
                  Cancel
                </button>
                <button
                  onClick={handleUpdate}
                  className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:bg-blue-300"
                  disabled={isUpdating}
                >
                  {isUpdating ? 'Updating...' : 'Update'}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageLayout>
  );
};

export default Status;


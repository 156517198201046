import { Grid, Typography, Button } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import Navbar from "../../PublicComponent/Navbar";
import Footer from "../../PublicComponent/Footer";
import Tools from "../../PublicComponent/Tools";
import Optimize from "../../PublicComponent/Optimize";
import View from "../../PublicComponent/View";
import Latency from "../../PublicComponent/Latency";
import CutControl from "../../PublicComponent/CutControl";
import Cloud from "../../PublicComponent/Cloud";
import DemoForm from "../../PublicComponent/DemoForm";




function Platform() {
  return (
   <div>
    <Navbar/>
  
    <div style={{    backgroundColor: "rgba(241, 242, 244, 0.50)", display: "flex",
          height: "744px",
          minHeight: "var(--Grid-Min-Height, 632px)",
          padding: "var(--Grid-Side-Margin, 96px)",
          alignItems: "center",
          alignContent: "center",
          gap: "64px var(--Grid-Gutter, 64px)",
          alignSelf: "stretch",
          flexWrap: "wrap", }}>
      
      <Grid container spacing={4} alignItems="center">
        {/* Left Content */}
        <Grid item xs={12} md={6}>
          <Typography
            variant="subtitle1"
            style={{
              color: "#E53E3E",
              fontWeight: "bold",
              textTransform: "uppercase",
              marginBottom: "8px",
            }}
          >
            ALL-IN-ONE PLATFORM
          </Typography>
          <Typography
            variant="h2"
            style={{
              color: "#1A202C",
              fontWeight: 600,
              maxWidth:"381px",
              fontSize: "64px",
              lineHeight: "76px",
              marginBottom: "8px",
            }}
          >
            Full-Stack Observability <br />
            for the Next Gen Builders
          </Typography>
          <Typography
            variant="body1"
            style={{
              color: "#4A5568",
              fontSize: "18px",
              fontWeight: 400,
              lineHeight: "1.6",
              marginBottom: "30px",
              maxWidth: "500px",
            }}
          >
            CB Vista brings all your cloud data together. Save up to 70% on
            monitoring costs while ensuring peak performance.
          </Typography>
          <Button
            variant="contained"
            endIcon={<ArrowForward style={{ fontSize: "22px" }}/>}
            style={{
              backgroundColor: "#E53E3E",
              color: "#fff",
              padding: "12px 24px",
              fontWeight: "bold",
              fontSize: "18px",
              borderRadius: "5px",
              textTransform: "none",
            }}
          >
            GET STARTED FOR FREE
          </Button>
        </Grid>

        {/* Right Content */}
        <Grid item xs={12} md={6}>
        <img src="/assets/Frame 28.png" alt="" />
        </Grid>
      </Grid>
      
    </div>
    
    <div><Tools/></div>
    <div><Optimize/></div>
    <div><View/></div>
    <div><Latency/></div>
    <div><CutControl/></div>
    <div><Cloud/></div>
   <div><DemoForm/></div>
   
    <Footer/>
    </div>
  );
}

export default Platform;


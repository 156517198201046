import { Grid } from '@mui/material'


function CutControl() {
  return (
    <div>
      <Grid container spacing={2} style={{ alignItems: "center" }}>
        <Grid item md={6}>
          <div style={{ marginLeft: "92px" }}>
            <span style={{ color: "#E53E3E", fontWeight: "bold", }}>Built-in cost control</span>
            <h1 style={{
              color: "black",
              fontSize: "48px",
              fontWeight: "bold",
              lineHeight: "1.2",
              margin: "20px 0",
            }}>
              Cut Costs, Not <br /> Corners
            </h1>
            <p
              style={{
                color: "#4A5568", // Slightly grayish text color
                fontSize: "18px",
                lineHeight: "1.6",
                maxWidth: "500px",
              }}
            >
Get complete visibility into your cloud spending and <br></br> uncover inefficiencies before they impact your growth. Our tools are designed to help you innovate without <br></br>overspending.
            </p>
          </div>
        </Grid>
        <Grid item md={6}>
        <img src="/assets/Frame 4.png" alt="Built-in cost control illustration"  />
        </Grid>
      </Grid>
    </div>
  )
}

export default CutControl;

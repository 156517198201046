


import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface TableProps {
  serviceCosts: any[];
  page: number;
  rowsPerPage: number;
  handleChangePage: (event: unknown, newPage: number) => void;
}

const CostTable: React.FC<TableProps> = ({
  serviceCosts,
  page,
  rowsPerPage,
  handleChangePage
}) => {
  const paginatedServiceCosts = serviceCosts.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <>
      <TableContainer component={Paper} style={{ marginTop: "20px" }}>
        <Table>
          <TableHead className="bg-gray-300">
            <TableRow>
              <TableCell>Service</TableCell>
              <TableCell>Total Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedServiceCosts.map((item: any, index: number) => (
              <TableRow key={index}>
                <TableCell>{item['service']}</TableCell>
                <TableCell>{typeof item['total_cost'] === 'number' ? item['total_cost'].toFixed(2) : item['total_cost']}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div className="flex items-center justify-center gap-2 mt-4 mb-8">
        <button
          onClick={() => handleChangePage(null, page - 1)}
          disabled={page === 0}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
        >
          PREVIOUS
        </button>

        {Array.from({ length: Math.min(7, Math.ceil(serviceCosts.length / rowsPerPage)) }).map((_, index) => (
          <button
            key={index}
            onClick={() => handleChangePage(null, index)}
            className={`px-4 py-2 text-sm font-medium rounded-md ${page === index
                ? 'bg-gray-900 text-white'
                : 'text-gray-700 bg-white border border-gray-300 hover:bg-gray-50'
              }`}
          >
            {index + 1}
          </button>
        ))}

        {Math.ceil(serviceCosts.length / rowsPerPage) > 7 && (
          <>
            <span className="px-2">...</span>
            <button
              onClick={() => handleChangePage(null, Math.ceil(serviceCosts.length / rowsPerPage) - 1)}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              {Math.ceil(serviceCosts.length / rowsPerPage)}
            </button>
          </>
        )}

        <button
          onClick={() => handleChangePage(null, page + 1)}
          disabled={page >= Math.ceil(serviceCosts.length / rowsPerPage) - 1}
          className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 disabled:opacity-50"
        >
          NEXT
        </button>
      </div>
    </>
  );
};

export default CostTable;

